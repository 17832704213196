import React, { useState, useEffect} from 'react';
import { connect } from "react-redux";

import { buildAPIRequest, security_fetch_params } from 'actions/index';
import {logout} from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';

const mapStateToProps = state => {
  return {
    apiLabel: state.conn.label,
    conn: state.conn,
  };
};

const ModelName = ({ parkId, idOptimisation, apiLabel, conn}) => {
    const [modelName, setModelName] = useState(null);
    const [trackedModel, setTrackedModel] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const getModelName = (parkId, idOptimisation) => {
      const localForageVar = !idOptimisation
        ? `model_name_${parkId}_${apiLabel}`
        : `model_name_${parkId}_${idOptimisation}_${apiLabel}`;
      localForage.getItem(localForageVar, function (err, value) {
          if (value) {
              if (
                value.updated_at < moment().unix() - 10000 ||
                (value.model.includes('tracking external') && !value.trackedModel)
              ) {
                downloadModelName(parkId, idOptimisation);
              } else {
                setModelName(value.model);
                setTrackedModel(value.trackedModel);
                setLoading(false);
              }   
          }
          else {
            downloadModelName(parkId, idOptimisation);
          }
      });
    }
    const downloadModelName = (parkId, idOptimisation) => {
        const urlString = !idOptimisation
          ? `/api/v1/parks/${parkId}/model_name`
          : `/api/v1/parks/${parkId}/model_name?result=${idOptimisation}`;
        const [url, headers] = buildAPIRequest(urlString);
        fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
          .then((response) => {
            if (response.ok) {
              return response.json().then(async (data) => {
                if (data.error) {
                  alertify.error("Response error");
                  console.log(data.error);
                  return
                }
                let externalData;
                if (data.data.includes('external park')) {
                  const trackedInfo = JSON.parse(data.data.match(/\[.*\]/)[0].replace(/'/g, '"'));
                  const trackedInstance = conn.loginInstances ? Object.keys(conn.loginInstances)
                    .map(key => conn.loginInstances[key])
                    .find(el => el.target === trackedInfo[0]).API_URL : conn.API_URL;
                  const response = await fetch(
                    `${trackedInstance}/api/v1/parks/${trackedInfo[1]}/model_name`, {
                      method: 'GET',
                      headers,
                      ...security_fetch_params
                    }
                  );
                  if (response.ok) {
                    const responceData = await response.json();
                    externalData = { data: responceData.data.replace('Signal optimizer', 'Tracked model')};
                  } else if (response.status === 401) {
                    logout();
                    return;
                  } else {
                    alertify.error('Error getting tracked model!', 5);
                  }
                }
                const localForageVar = !idOptimisation
                  ? `model_name_${parkId}_${apiLabel}`
                  : `model_name_${parkId}_${idOptimisation}_${apiLabel}`;
                data.updated_at = moment().unix();
                localForage.setItem(localForageVar, {
                  model: data.data, 
                  ...(data.data.includes('external park') ? { trackedModel: externalData?.data } : {}), 
                  updated_at: moment().unix()
                }, function (err) {
                  if (err) {
                      console.log(err);
                  }
                });
                setModelName(data.data);
                if (data.data.includes('external park')) setTrackedModel(externalData ? externalData.data : 'Tracked model: N/A');
                setLoading(false);
              });
            } else if (response.status === 401) {
              logout();
              return;
            } else if (response.status === 404) {
              alertify.error('Model name is N/A!');
              return;
            }
            return Promise.reject(response);
          })
          .catch((error) => {
            alertify.error(error.message, 'error', 5);
            return [false, null];
          });
    }

    useEffect(() => {
      if(parkId) {
        setLoading(true);
        getModelName(parkId, idOptimisation);
      }
    // eslint-disable-next-line
    },[parkId, idOptimisation]);

    return (
      <div>
        {modelName && !loading ? (
          <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <span>{modelName}</span>
            {trackedModel && (<span>{trackedModel}</span>)}
          </div>
        ) : 'Model: N/A'}
      </div>
    )

}

const ModelNameConnect = connect(mapStateToProps)(ModelName);
export default ModelNameConnect;
