import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormControl,
  Grid,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialTable from '@material-table/core';
import { Input } from '@material-ui/icons';
import alertify from 'alertifyjs';
import classNames from 'classnames';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import CustomButton from 'components/CustomButtons/Button';
import LoginPage from 'views/Login/Oops.jsx';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { 
  security_fetch_params, 
  get_markets_data, 
  set_audit_alerts_type,
  set_audit_alerts_status,
} from 'actions/index';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import { logout } from 'utils/auth';
import * as helper from './AuditDataHelper'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    selectedType: state.auditdata.alertsType,
    selectedStatus: state.auditdata.alertsStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_markets_data: (data) => dispatch(get_markets_data(data)),
  set_selected_type: (data) => dispatch(set_audit_alerts_type(data)),
  set_selected_status: (data) => dispatch(set_audit_alerts_status(data)),
});

const AuditAlerts = ({ 
  classes, 
  isLoggedIn,
  selectedType,
  selectedStatus,
  set_selected_type,
  set_selected_status,
  helpModeActive,
  tooltips,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [alertsData, setAlertsData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(25)

  const getAlertsData = async (
    type, 
    status, 
  ) => {
    let params = `?${
      type !== 'All' ? `type=${type}` : ''
    }${
      'value' in status ? `&enabled=${status.value}` : ''
    }`;

    const response = await fetch(
      `https://kube.sama.energy/gateway/api/audit_alerts${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      }
    );

    if (response.status === 401) {
      logout();
      return null;
    }

    if (response.ok) {
      const data = await response.json();
      setAlertsData(data);
    } else {
      alertify.error('Cannot get Audit Alerts Data!', 5);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAlertsData(selectedType, selectedStatus)
  }, []);

  useEffect(() => {
    if (alertsData) {
      const tableData = alertsData.map((el) => ({
        park_name: el.park.park_name,
        type: el.type,
        cron: el.cron,
        enabled: el.enabled,
        details: (
          <CustomTooltip
            title={tooltips?.details || ''}
            disableFocusListener={!helpModeActive}
            disableHoverListener={!helpModeActive}
            disableTouchListener={!helpModeActive}
          >
            <Link
              to={{
                pathname: '/audit_alert_details',
                state: {
                  id: el.id,
                },
              }}
            >
              {' '}
              <Input />
            </Link>
          </CustomTooltip>
        ) 
      }))
      setTableData(tableData);
      setIsLoading(false);
    }
  }, [alertsData, helpModeActive, tooltips])
  
  const handleFilterChange = (field, value) => {
    setIsLoading(true);
    if (value) {
      if (field === 'type') {
        set_selected_type(value);
        getAlertsData(value, selectedStatus);
      } else if (field === 'status') {
        set_selected_status(value);
        getAlertsData(selectedType, value);
      }
    }
  }

  const typeOptions = ['All', 'deliver_nominations', 'production_forecast'];
  const statusOptions = [
    { name: 'All' }, 
    { name: 'Enabled', value: true }, 
    { name: 'Disabled', value: false }
  ];

  const tableHeads = ['Park Name', 'Type', 'Cron', 'Enabled', 'Details'];

  const makeColumns = (tableHeads) => {
    return tableHeads.map((head, id) => {
      return {
        title: head,
        field: head.toLowerCase().replace(' ', '_'),
        width: 100,
      };
    });
  };

  if (isLoggedIn) {
    return (
      <Grid container spacing={2}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classNames(classes.cardTitleWhite)}>
              Audit Alerts
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={4} justifyContent='space-between'>
                  <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className={classNames(
                            classes.formControl,
                            classes.fullWidth,
                            classes.marginBottom
                          )}
                        >
                          <ParkAutocomplete
                            id="type"
                            label="Type"
                            options={typeOptions}
                            value={selectedType}
                            getOptionLabel={(option) => option}
                            disableClearable={true}
                            onChange={(_, v) => handleFilterChange('type', v)}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className={classNames(
                            classes.formControl,
                            classes.fullWidth,
                            classes.marginBottom
                          )}
                        >
                          <ParkAutocomplete
                            id="status"
                            label="Status"
                            options={statusOptions}
                            value={selectedStatus}
                            getOptionLabel={(option) => option.name}
                            disableClearable={true}
                            onChange={(_, v) => handleFilterChange('status', v)}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <Link
                      to={{
                        pathname: '/audit_alert_details',
                      }}
                    >
                      <CustomButton
                        className={classNames(classes.fullWidth, )}
                        color='primary'
                      >
                        Create New Alert
                      </CustomButton>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  columns={makeColumns(tableHeads)}
                  data={tableData}
                  onRowsPerPageChange={(e) => setPageSize(e)}
                  isLoading={isLoading}
                  options={{
                    showTitle: false,
                    search: true,
                    tableLayout: 'fixed',
                    maxBodyHeight: window.innerHeight - 280,
                    headerStyle: {
                      position: 'sticky',
                      top: 0,
                      zIndex: 2,
                      color: primaryColor,
                      fontSize: '1em',
                      padding: '12px 0',
                      textAlign: 'center',
                      fontWeight: 500,
                    },
                    cellStyle: {
                      textAlign: 'center',
                    },
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    pageSizeOptions: [25, 50, 100],
                    pageSize: pageSize,
                    sorting: true,
                    multiSort: true,
                    serverSide: true,
                    maxColumnSort: 2,
                  }}
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedAuditAlerts = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditAlerts);
export default withStyles(helper.styles)(ConnectedAuditAlerts);
