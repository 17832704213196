import React, { Component } from 'react';

import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

import { Link } from 'react-router-dom';
import { HelpOutline, Textsms } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';

import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

class Sessions extends Component {
  constructor() {
    super();
    this.state = {
      selected: [],
    };
  }

  // move to componentDidMount ?
  componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.state.selected) {
      this.setState({ selected: nextProps.selected });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.optimisations !== this.props.optimisations || 
      nextState !== this.state || 
      nextProps.helpModeActive !== this.props.helpModeActive ||
      nextProps.tooltips !== this.props.tooltips;
  }

  handleChangeSelection = (items) => {
    this.setState({ selected: items });
    this.props.set_selected(items);
  };

  handleChangePage = (event, page) => {
    this.props.setCurPage(page);
  };
  
  render() {
    const { classes, optimisations, page, optTotalItems, park, winHeight, handleOptTypeChange, helpModeActive} = this.props;
    let columns = [
      { field: 'id', headerName: 'ID', hide: true, width: 50},
      {
        field: 'time',
        headerName: 'Optimisation time UTC',
        flex: 2,
        minWidth: 130,
        valueGetter: (params) => moment(params.row.time).format('MMM DD HH:mm'),
      },
      {
        field: 'date',
        headerName: 'Delivery date',
        flex: 2,
        minWidth: 130,
        valueGetter: (params) => moment(params.row.date).format('MMM DD'),
        sortComparator: (v1, v2, param1, param2) =>
          (moment(param1.value, 'MMM DD').unix() - moment(param2.value, 'MMM DD').unix()),
      },
      {
        field: 'afternoon_run',
        headerName: 'Opt. type',
        flex: 1,
        minWidth: 75,
        align: 'center',
        valueGetter: (params) =>
          params.row.afternoon_run ? 'ID' : 'DA',
      },
      {
        field: 'action',
        headerName: (
          <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <span>{'Details'}</span>
            {helpModeActive && (
              <CustomTooltip title={this.props.tooltips?.details_column || ''}>
                <HelpOutline fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        flex: 1,
        minWidth: 90,
        align: 'center',
        disableExport: true,
        renderCell: (params) => (
          <CustomTooltip
            title={this.props.tooltips?.details || ''}
            disableFocusListener={!helpModeActive}
            disableHoverListener={!helpModeActive}
            disableTouchListener={!helpModeActive}
          >
            <Link
              to={{
                pathname: '/optimisation_log',
                state: {
                  currentData: {
                    id: params.row.id,
                    date: params.row.date,
                    optTime: params.row.time,
                  },
                  optimisations,
                  park: park,
                },
              }}
            >
              <Textsms />
            </Link>
          </CustomTooltip>
        ),
      },
      {
        field: 'expected_profit',
        headerName: 'Expected Profit',
        type: 'number',
        flex: 1.5,
        minWidth: 110,
      },
      {
        field: 'da_profit',
        headerName: 'DA Profit',
        type: 'number',
        flex: 1.5,
        minWidth: 110,
      },
      {
        field: 'am_profit',
        headerName: 'ID Profit',
        type: 'number',
        flex: 1.5,
        minWidth: 110,
      },
      {
        field: 'ib_profit',
        headerName: 'IB Profit',
        type: 'number',
        flex: 1.5,
        minWidth: 110,
      },
      {
        field: 'total_profit',
        headerName: 'Total Profit',
        type: 'number',
        flex: 2,
        minWidth: 130,
        valueGetter: (params) =>
          `${(
            params.row.da_profit +
            params.row.am_profit +
            params.row.ib_profit
          ).toFixed(2)}`,
      },
    ];

    if(park?.extra_config?.price_model_op?.includes('ensemble')){
      const entryOver = park?.optimization_job?.entry_over;
      let flagId = false;
      // check if there is any continuous stage
      if(entryOver){
        Object.keys(entryOver).forEach((key) => {
          if (entryOver[key]['afternoon_run']) {
            flagId = true;
          }
        });
      }
      if (flagId) {
        columns.splice(columns.length - 1, 0, {
          field: 'price_model_id',
          headerName: 'Final',
          flex: 1.1,
          valueGetter: () => 'Yes',
          });
      } else {
        columns.splice(columns.length - 1, 0, {
          field: 'price_model_id',
          headerName: 'Final',
          flex: 1.1,
          valueGetter: (params) => (!params.row.price_model_id ? 'Yes' : 'No'),
          });
      }
    }

    const rowsPerPage = 100;
    if (optimisations === undefined) {
      return <div />;
    } else {
      return (
        <div className={classes.tableResponsive}>
          {optimisations && (
            <div className={classes.container}>
              <div className={`${classes.table} ${classes.tableResponsive}`} style={{height: winHeight - 50}}>
                <DataGrid
                  className={classes.headerDataGrid}
                  rows={optimisations}
                  columns={columns}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[100]}
                  checkboxSelection
                  hideFooterPagination
                  headerHeight={100}
                  selectionModel={this.state.selected}
                  onSelectionModelChange={this.handleChangeSelection}
                  hideFooter
                  onFilterModelChange={(model) => {
                    if(!model.items.find(el => el.columnField === 'afternoon_run')) 
                      return handleOptTypeChange({ items: [] });
                      
                    model.items.forEach(el => {
                      if (el.columnField === 'afternoon_run') {
                       return handleOptTypeChange({ items: [el]});
                      }
                    });
                  }}
                  components={{
                    Toolbar: () => <GridToolbarExport className={classes.exportBtn}/>
                  }}
                />
              </div>
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={optTotalItems}
            rowsPerPage={rowsPerPage}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
          />
        </div>
      );
    }
  }
}

Sessions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyle)(Sessions);
