import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import LoginPage from "views/Login/Oops.jsx";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Scores from 'components/MarketsDashboard/ScoreDetails';

import { get_market_scores } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const mapStateToProps = state => {
  return { isLoggedIn: state.login.loggedIn, value: 0, loading: state.marketscores.loading,
    scores: state.marketscores.scores,
    };
};

const mapDispatchToProps = (dispatch) => ({
  get_market_scores: data => dispatch(get_market_scores(data))
});


class MarketScores extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps !== undefined){
      if(nextProps.isLoggedIn === true && this.state.value === 0){
        this.props.get_market_scores(this.props.location.state);
        this.setState({ value: 1 });
      }
    }
    return true;
  }

  componentDidMount(){
    this.props.get_market_scores(this.props.location.state);
    this.setState({ value: 1 });
  }


  render() {
    if (this.props?.isLoggedIn) {
      if (this.props.loading) {
        return (
          <div className="loader" alt="Loading report..."/>
        );
      }
    } else {
      return (<LoginPage />);
    }

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardHeader color="primary">
                <h3>{this.props.location.state.country + ' - ' + this.props.location.state.importance + ' - ' + this.props.location.state.source}</h3>
              </CardHeader>
              <CardBody>
                <Scores
                  scores={this.props.scores}
                  winHeight={window.innerHeight}
                  helpModeActive={this.props.helpModeActive}
                  tooltips={this.props.tooltips}
                />
              </CardBody>
              <CardFooter xs={12} sm={12} md={12}>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

MarketScores.propTypes = {
  classes: PropTypes.object.isRequired
};

const MarketScoresConnect = connect(mapStateToProps, mapDispatchToProps)(MarketScores);
export default withStyles(dashboardStyle)(MarketScoresConnect);
