import { stocksChartOptions, makeSeries } from "variables/charts";
import {zeroLineColor} from "assets/jss/material-dashboard-react";

export const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  spacing: {
    marginRight: "1rem",
  },
  bgColor: {
    backgroundColor: "#fff",
  },
  bgColorAlt: {
    backgroundColor: "#eee",
  },
  textColor: {
    color: "#000",
  },
  width300: {
    width: "300px",
  },
  whiteArrow: {
    color: "white"
  },
  arrowRight: {
    marginLeft: "5px"
  },
  arrowLeft: {
    marginRight: "5px"
  },
};

export const calcMinMaxValue = (array) => {
  const min = Math.min.apply(null, array);
  const max = Math.max.apply(null, array);
  return -min >= max ? -min : max;
};

export const calcHourData = (data, dt, par) => {
  const itemsPerHour = 1 / dt;
  let hourData = [];
  for (let i = 0; i < data.length; i = i + itemsPerHour) {
    let sumValue = 0;
    for (let j = 0; j < itemsPerHour; j++) {
      sumValue += data[i + j];
    }
    if(par === 'vol')
      hourData.push(Number(sumValue.toFixed(2)));
    else if (par === 'price')
      hourData.push(Number((sumValue * dt).toFixed(2)));
  }
  return hourData;
};

const dataWithDates = (datesAr, dataAr) =>
  datesAr.map((el, id) => [el, dataAr[dataAr.length - datesAr.length + id]]);

const addOptions = {
  yAxis: [
    {
      title: {
        text: "Volume, MWh",
      },
    },
  ],
  rangeSelector: {
    enabled: false,
  },
};

export const chartOptions = (
  datesAr,
  substractedPrices,
  legend,
  daConfirmationData,
  isWithGeneration,
  daDifferenceForecast,
  daDifferenceActual,
  imbGranData,
  daPricesData,
  imbUPricesGranData,
  imbDPricesGranData,
  pnData,
  idAllTrades,
  intraDayVolExec,
  currency
) => {

  let daSeries = [
    makeSeries(
      "DA Nom",
      pnData.map((vol, ind) => [datesAr[ind], vol]),
      { valueDecimals: 2, colorIndex: 1, visible: isWithGeneration ? false : true }
    ),
    makeSeries("DA conf", dataWithDates(datesAr, daConfirmationData), {
      valueDecimals: 2,
      colorIndex: 0,
      visible: isWithGeneration ? false : true
    }),
    makeSeries(legend, dataWithDates(datesAr, substractedPrices), {
      valueDecimals: 2,
      colorIndex: 3,
      yAxis: 1,
    }),
  ];
  // if park have generations then add two curves in graph
  if (isWithGeneration) {
    daSeries.splice(2, 0, makeSeries("DA Nom - DA Forecast", dataWithDates(datesAr, daDifferenceForecast), {
      valueDecimals: 2,
      colorIndex: 4,
    }));
    daSeries.splice(2, 0, makeSeries("DA Nom - Actual production", dataWithDates(datesAr, daDifferenceActual), {
      valueDecimals: 2,
      colorIndex: 5,
    }));
  };

  const minDaChart = Math.max(
    calcMinMaxValue(pnData),
    calcMinMaxValue(daConfirmationData),
    calcMinMaxValue(daDifferenceForecast),
    calcMinMaxValue(daDifferenceActual),
    1
  );

  const optimisationPdVol = {
    ...stocksChartOptions({ filename: `Day ahead volumes` }),
    yAxis: substractedPrices
      ? [
          {
            title: {
              text: "Volume, MWh",
            },
            min: -minDaChart,
            max: minDaChart,
          },
          {
            title: {
              text: `Price, ${currency}/MWh`,
            },
            opposite: false,
            min: -calcMinMaxValue(substractedPrices),
            max: calcMinMaxValue(substractedPrices),
            plotLines : [{
              color: zeroLineColor,
              value: 0, // Value of where the line will appear
              width: 3 // Width of the line    
            }],
          },
        ]
      : [...addOptions.yAxis],
    rangeSelector: {
      enabled: false,
    },
    series: daSeries,
  };
  const optimisationImbVol = {
    ...stocksChartOptions({ filename: `IMB Volumes` }),
    ...addOptions,
    series: [
      makeSeries("IMB vol Total", dataWithDates(datesAr, imbGranData), {
        valueDecimals: 2,
      }),
    ],
  };
  const optimisationPrices = {
    ...stocksChartOptions({ filename: `Prices` }),
    ...addOptions,
    yAxis: [
      {
        title: {
          text: `Price, ${currency}`,
        },
      },
    ],
    series: [
      makeSeries("DA prices", dataWithDates(datesAr, daPricesData), {
        valueDecimals: 2,
        colorIndex: 0,
      }),
      makeSeries("IMBU prices", dataWithDates(datesAr, imbUPricesGranData), {
        valueDecimals: 2,
        colorIndex: 1,
      }),
      makeSeries("IMBD prices", dataWithDates(datesAr, imbDPricesGranData), {
        valueDecimals: 2,
        colorIndex: 2,
      }),
    ],
  };

  //-------------------------------ID trading chart data
  const optimisationPaVol = {
    ...stocksChartOptions({ filename: `IntraDay volumes` }),
    ...addOptions,
    series: [
      makeSeries(
        "ID Nom",
        pnData.map((vol, ind) => [datesAr[ind], vol]),
        { valueDecimals: 2, colorIndex: 1 }
      ),
      makeSeries(
        "ID Exec",
        datesAr.map((date, id) => {
          const findOrder = idAllTrades.filter((el) => el[0] === date);
          if (findOrder.length) return [date, findOrder[0][1]];
          return [date, 0];
        }),
        { valueDecimals: 2, colorIndex: 3 }
      ),
      makeSeries("ID Exec Total", dataWithDates(datesAr, intraDayVolExec), {
        valueDecimals: 2,
        colorIndex: 2,
      }),
    ],
  };
  return {
    optimisationPdVol,
    optimisationImbVol,
    optimisationPrices,
    optimisationPaVol,
  };
};

