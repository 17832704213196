import React, { useEffect, useState } from 'react';

import { FormControl, TextField, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { Autocomplete } from '@material-ui/lab';
import CustomButton from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import { buildAPIRequest, security_fetch_params } from 'actions/index';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import { logout } from 'utils/auth';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

const fields = {
  cron_minute: {
    default: '*',
    type: 'string',
  },
  cron_hour: {
    default: '*',
    type: 'string',
  },
  cron_day_of_month: {
    default: '*',
    type: 'string',
  },
  kwargs: {
    default: '{}',
    type: 'multiline',
  },
  enabled: {
    default: true,
    type: 'boolean',
  },
};
const defaultScheduleJob = {
  job: '',
};
Object.keys(fields).forEach((item) => {
  defaultScheduleJob[item] = fields[item].default;
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ScheduleJobsDetails = ({ location, classes, helpModeActive, tooltips }) => {
  const [schedJob, setSchedJob] = useState({});
  const [metaData, setMetaData] = useState({});

  const parkId = location.state?.parkId;
  const schedJobId = location.state?.schedJobId;
  const typeOperation = location.state?.typeOperation;

  const getMetaData = () => {
    let [url, headers] = buildAPIRequest(`/api/windparks/schedule/metadata`);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            if (data) setMetaData(data);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const schedJobRequest = (urlReq, reqType, message, body = null) => {
    let [url, headers] = buildAPIRequest(urlReq);
    const bodySchedJob = JSON.parse(JSON.stringify(schedJob));
    try {
      bodySchedJob['kwargs'] = JSON.parse(bodySchedJob['kwargs']);
    } catch (error) {
      console.log(error);
    }
    alertify.warning('Start updating data', 5);
    fetch(url, {
      method: reqType,
      headers: headers,
      body: body ? body : JSON.stringify(bodySchedJob),
      ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          alertify.success(message, 5);
        } else if (response.status === 401) {
          logout();
          return;
        } else if (response.status === 400) {
          response.json().then((data) => {
            if (data.validation_error) {
              alertify.error(
                `${data.validation_error.body_params?.[0]?.loc?.[0]} - ${data.validation_error.body_params?.[0]?.msg}`,
                5
              );
            }
          });
        } else {
          alertify.error(response.statusText, 5);
          getMetaData();
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };
  const getSchedJob = () => {
    let [url, headers] = buildAPIRequest(
      `/api/windparks/${parkId}/schedule/${schedJobId}`
    );
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            const schedJob = JSON.parse(JSON.stringify(defaultScheduleJob));
            Object.keys(schedJob).forEach((item) => {
              if (data[item] !== undefined) {
                if (item === 'kwargs') {
                  schedJob[item] = JSON.stringify(data[item]);
                } else schedJob[item] = data[item];
              }
            });
            if (data) setSchedJob(schedJob);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveClick = () => {
    if (typeOperation === 'edit') {
      schedJobRequest(
        `/api/windparks/${parkId}/schedule/${schedJobId}`,
        'PATCH',
        'Data Updated'
      );
    } else if (typeOperation === 'new') {
      schedJobRequest(
        `/api/windparks/${parkId}/schedule`,
        'POST',
        'Schedule job has created'
      );
    }
  };

  const handleEnableClick = () => {
    if (typeOperation === 'edit') {
      const newSchedJob = JSON.parse(JSON.stringify(schedJob));
      newSchedJob.enabled = !newSchedJob.enabled;
      setSchedJob({ ...newSchedJob });
      newSchedJob['kwargs'] = JSON.parse(newSchedJob['kwargs']);
      if (schedJobId) {
        schedJobRequest(
          `/api/windparks/${parkId}/schedule/${schedJobId}`,
          'PATCH',
          `Schedule job has ${newSchedJob.enabled ? 'enabled' : 'disabled'}`,
          JSON.stringify(newSchedJob)
        );
      }
    }
  };

  const handleChangeJob = (event, value) => {
    const newSchedJob = JSON.parse(JSON.stringify(schedJob));
    newSchedJob.job = value;
    setSchedJob(newSchedJob);
  };

  useEffect(() => {
    if (typeOperation === 'edit') {
      getSchedJob();
    } else if (typeOperation === 'new') {
      setSchedJob(defaultScheduleJob);
    }
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeJobField = (event) => {
    const newSchedJob = JSON.parse(JSON.stringify(schedJob));
    const value = event.target.value;
    newSchedJob[event.target.name] = value;
    setSchedJob(newSchedJob);
  };

  const job = schedJob?.job;
  const formJobCreate = Object.keys(schedJob).length ? (
    <Grid container spacing={4}>
      {Object.keys(fields).map((item, index) => {
        let component;
        if (fields[item].type === 'string') {
          component = (
            <TextField
              className={classes.textField}
              type="text"
              label={item}
              name={item}
              value={schedJob[item]}
              margin="normal"
              onChange={handleChangeJobField}
            />
          );
        } else if (fields[item].type === 'multiline') {
          component = (
            <TextField
              className={classes.textField}
              type="text"
              label={item}
              name={item}
              value={schedJob[item]}
              margin="normal"
              multiline
              onChange={handleChangeJobField}
            />
          );
        }
        return (
          <Grid item xs={12} sm={4} key={index}>
            <FormControl className={classNames(classes.fullWidth)}>
              {component}
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <></>
  );

  const loader = (
    <div className={classes.loadingContainer}>
      <div className="loader" alt="Loading report..." />
    </div>
  );

  return (
    <Grid container spacing={4}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Schedule Job Details</h4>
        </CardHeader>
        <CardBody>
          {Object.keys(schedJob).length && Object.keys(metaData).length ? (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classNames(classes.fullWidth)}>
                  <Autocomplete
                    disablePortal
                    id="job"
                    name="job"
                    value={job || null}
                    options={metaData.jobs}
                    renderInput={(params) => (
                      <TextField {...params} label="Job" />
                    )}
                    onChange={handleChangeJob}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {formJobCreate}
              </Grid>
            </Grid>
          ) : (
            loader
          )}
        </CardBody>
        <CardFooter>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <CustomButton 
                color="primary" 
                onClick={handleSaveClick} 
                helpModeActive={helpModeActive} 
                tooltip={tooltips?.save_button || ''}
              >
                Save Data
              </CustomButton>
            </Grid>
            {Object.keys(schedJob).length && typeOperation === 'edit' ? (
              <Grid item xs={12} sm={6}>
                <CustomButton 
                  color="primary" 
                  onClick={handleEnableClick}
                  tooltip={schedJob.enabled ? "Disable Schedule Job" : "Enable Schedule Job"}
                  helpModeActive={helpModeActive}
                >
                  {schedJob.enabled ? 'Disable' : 'Enable'}
                </CustomButton>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardFooter>
      </Card>
    </Grid>
  );
};

const ConnectedScheduleJobsDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleJobsDetails);
export default withStyles(dashboardStyle)(ConnectedScheduleJobsDetails);
