import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormControl,
  TextField,
  Grid,
  TablePagination,
  InputAdornment,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialTable from '@material-table/core';
import { HelpOutline, Input, Search } from '@material-ui/icons';
import { DateTimePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import alertify from 'alertifyjs';
import classNames from 'classnames';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import CustomButton from 'components/CustomButtons/Button';
import LoginPage from 'views/Login/Oops.jsx';
import { 
  security_fetch_params, 
  get_markets_data, 
  set_market_filter, 
  set_variable_filter, 
  set_source_filter,
  set_auditor_filter,
  set_audit_summary_loading,
  set_importance_filter,
  set_audit_date_from,
  set_audit_date_to,
  set_audit_search
} from 'actions/index';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import { logout } from 'utils/auth';
import localforage from 'localforage';
import moment from 'moment';
import { DATE_FORMAT_DASH } from 'constants/general';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as helper from './AuditDataHelper';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    market: state.auditdata.selectedMarket,
    variable: state.auditdata.selectedVariable,
    source: state.auditdata.selectedSource,
    auditor: state.auditdata.selectedAuditor,
    loading: state.auditdata.loading,
    importance: state.auditdata.importance,
    date_from: state.auditdata.dateFrom,
    date_to: state.auditdata.dateTo,
    search: state.auditdata.search,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_markets_data: (data) => dispatch(get_markets_data(data)),
  set_filtered_market: (data) => dispatch(set_market_filter(data)),
  set_filtered_variable: (data) => dispatch(set_variable_filter(data)),
  set_filtered_source: (data) => dispatch(set_source_filter(data)),
  set_filtered_auditor: (data) => dispatch(set_auditor_filter(data)),
  set_loading: (data) => dispatch(set_audit_summary_loading(data)),
  set_filtered_importance: (data) => dispatch(set_importance_filter(data)),
  set_audit_date_from: (data) => dispatch(set_audit_date_from(data)),
  set_audit_date_to: (data) => dispatch(set_audit_date_to(data)),
  set_search: (data) => dispatch(set_audit_search(data)),
});

const AuditData = ({ 
  classes, 
  isLoggedIn, 
  get_markets_data, 
  markets, 
  market, 
  variable,
  source,
  auditor,
  loading,
  importance,
  date_from,
  date_to,
  search,
  set_filtered_market, 
  set_filtered_variable,
  set_filtered_source, 
  set_filtered_auditor,
  set_loading,
  set_filtered_importance,
  set_audit_date_from,
  set_audit_date_to,
  set_search,
  helpModeActive,
  tooltips
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState(null);
  const [auditors, setAuditors] = useState([]);
  const [marketVariables, setMarketVariables] = useState([]);
  const [variables, setVariables] = useState([]);
  const [intervals, setIntevals] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [selectedAuditor, setSelectedAuditor] = useState('');
  const [selectedInterval, setSelectedInterval] = useState('');
  const [intervalValue, setIntervalValue] = useState('');
  const [dateFrom, setDateFrom] = useState(
    dayjs().add(-1, 'day').format('YYYY-MM-DDTHH:mm:ss')
  );
  const [dateTo, setDateTo] = useState(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
  const [selectedGranularity, setSelectedGranularity] = useState('1');
  const [customAuditData, setCustomAuditData] = useState(null);
  const [rule, setRule] = useState('');
  const [filterVariables, setFilterVariables] = useState([]);
  const [sources, setSources] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);

  const [sorting, setSorting] = useState({ market: null, variable: null });
  const searchRef = useRef(null);
  const sortedMarkets = useMemo(() => [...markets].sort((a, b) => a.name > b.name), [markets]);

  const getSummaryAuditData = async (
    pageNum, 
    pageSize, 
    market_name, 
    variable_name, 
    source, 
    startDate, 
    endDate, 
    auditor, 
    importance, 
    sorting
  ) => {
    let params = `?page=${pageNum}&size=${pageSize}&acknowledged=false${
      market_name && market_name !== 'All' ? `&market_name=${market_name}` : ''
    }${
      variable_name && variable_name !== 'All'
        ? `&variable_name=${variable_name}`
        : ''
    }${
      source && source !== 'All' ? `&variable_source=${source}` : ''
    }${
      startDate ? `&date_min=${startDate}` : ''
    }${
      endDate ? `&date_max=${endDate}` : ''
    }${
      auditor && auditor !== 'All' ? `&auditor=${auditor}` : ''
    }${
      importance && importance !== 'All' ? `&importance=${importance.toLowerCase()}` : ''
    }`;

    if (sorting) {
      let orderStr = ''
      Object.keys(sorting).forEach((key, i) => {
        let orderBy = '';

        if (!sorting[key]) {  
          return
        } else if (i === 0 || orderStr === '') {        
          orderBy = sorting[key].direction === 'desc' ? `-${sorting[key].field}` : `${sorting[key].field}`;
        } else {
          orderBy = sorting[key].direction === 'desc' ? `,-${sorting[key].field}` : `,${sorting[key].field}`;
        }

        orderStr += orderBy;
      })
      params += orderStr ? `&order_by=${orderStr}` : '';
    }

    const response = await fetch(
      `https://kube.sama.energy/gateway/api/market_data_audit_entries/summary${params}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      }
    );

    if (response.status === 401) {
      logout();
      return null;
    }

    if (response.ok) {
      const data = await response.json();
      setSummary(data);
    } else {
      alertify.error('Cannot get Markets Audit Summary Data!', 5);
    }
  };

  const getAuditors = async () => {
    const stored = await localforage.getItem('auditors');

    if (!stored || stored.updated_at < moment().unix() - 86400) {
      const response = await fetch(
        'https://kube.sama.energy/gateway/api/market_data_audit_entries/auditors',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          ...security_fetch_params,
        }
      );

      if (response.status === 401) {
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        data.updated_at = moment().unix();
        localforage.setItem('auditors', data);
        setAuditors(data.data);
      } else {
        alertify.error('Cannot get auditors data!', 5);
      }
    } else {
      setAuditors(stored.data);
    }
  };

  const getMarketVariables = async (id) => {
    const stored = await localforage.getItem(`market_rich_variables_${id}`);

    if (!stored || stored.updated_at < moment().unix() - 604800) {
      const response = await fetch(
        `https://kube.sama.energy/gateway/api/market_rich_variables?market_id=${id}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          ...security_fetch_params,
        }
      );

      if (response.status === 401) {
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        const obj = { data, updated_at: moment().unix() };
        localforage.setItem(`market_rich_variables_${id}`, obj);
        return data; 
      } else {
        alertify.error('Cannot get market variables!', 5);
      }
    } else {
      return stored.data;
    }
  };

  const getIntervals = async () => {
    const stored = await localforage.getItem('audit_intervals');

    if (!stored || stored.updated_at < moment().unix() - 86400) {
      const response = await fetch(
        'https://kube.sama.energy/gateway/api/market_data_audit_entries/intervals',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          ...security_fetch_params,
        }
      );

      if (response.status === 401) {
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        data.updated_at = moment().unix();
        localforage.setItem('audit_intervals', data);
        setIntevals(data.data);
      } else {
        alertify.error('Cannot get intervals data!', 5);
      }
    } else {
      setIntevals(stored.data);
    }
  };

  useEffect(() => {
    get_markets_data();
    getSummaryAuditData(page, pageSize, market.name, search ? search : variable.name, source, date_from, date_to, auditor, importance, sorting);
    getAuditors();
    getIntervals();
    if (market.id) {
      const getVariables = async () => {
        const data = await getMarketVariables(market.id)
        setSources([...new Set(data.map(el => el.source).sort((a, b) => a > b))])
        setFilterVariables(data);
        alertify.success('Variable list is loaded!', 5);
      }

      alertify.notify('Start getting variable list...', 5);
      getVariables();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedMarket) {
      const getVariables = async () => {
        const data = await getMarketVariables(selectedMarket.id)
        setMarketVariables(data);
        alertify.success('Variable list is loaded!', 5);
      }

      alertify.notify('Start getting variable list...', 5);
      getVariables();
    }
  }, [selectedMarket]);

  useEffect(() => {
    if (marketVariables.length) {
      const newVariables = marketVariables
        .map((variable) => ({
          ...variable,
          detailedName: `${variable.source}: ${variable.name}`,
        }))
        .sort((a, b) => a.detailedName > b.detailedName);
      setVariables(newVariables);
    }
  }, [marketVariables]);

  useEffect(() => {
    if (sortedMarkets.length && summary) {
      const data = summary.items.map((marketAuditSum, index) => {
        return {
          id: index,
          market: marketAuditSum.market.name,
          variable_name: `${marketAuditSum.variable.source}: ${marketAuditSum.variable.name}`,
          auditor: marketAuditSum.auditor,
          total: marketAuditSum.total,
          details: (
            <CustomTooltip
              title={tooltips?.details || ''}
              disableFocusListener={!helpModeActive}
              disableHoverListener={!helpModeActive}
              disableTouchListener={!helpModeActive}
            >
              <Link
                to={{
                  pathname: '/auditmarketdatadetails',
                  state: {
                    market_id: marketAuditSum.market.id,
                    market: marketAuditSum.market.name,
                    variable_id: marketAuditSum.variable.id,
                    variable_name: `${marketAuditSum.variable.source}: ${marketAuditSum.variable.name}`,
                    variable: `${marketAuditSum.variable.source}/${marketAuditSum.variable.name}`,
                    auditor: marketAuditSum.auditor,
                  },
                }}
              >
                {' '}
                <Input />
              </Link>
            </CustomTooltip>
          ),
        };
      });
      setTableData(data);
      set_loading(false);
      setIsLoading(false);
    }
  }, [sortedMarkets, summary, helpModeActive, tooltips]);

  useEffect(() => {
    if (
      selectedMarket &&
      selectedVariable &&
      selectedAuditor &&
      selectedInterval &&
      selectedGranularity
    ) {
      let interval = selectedInterval;
      if (
        selectedInterval.includes("-<N>-") && 
        selectedInterval.includes("absolute") &&
        intervalValue && 
        dateTo
      ) {
        interval = selectedInterval.replace(/<N>|<YYYY-MM-DDTHH:MM:SS>/g, (matched) => {
          return matched === "<N>" ? intervalValue : `${dateTo}:00`
        })
      } else if (
        selectedInterval.includes("-<N>-") &&
        intervalValue
      ) {
        interval = selectedInterval.replace('<N>', intervalValue);
      } else if (
        selectedInterval ===
          'absolute|<YYYY-MM-DDTHH:MM:SS>|<YYYY-MM-DDTHH:MM:SS>' &&
        dateFrom &&
        dateTo
      ) {
        interval = `absolute|${dateFrom}:00|${dateTo}:00`;
      } else if (selectedInterval.includes("absolute") && dateTo) {
        interval = selectedInterval.replace("<YYYY-MM-DDTHH:MM:SS>", `${dateTo}:00`);
      }

      setCustomAuditData({
        marketId: selectedMarket.id,
        variableId: selectedVariable.id,
        auditor: selectedAuditor,
        interval: interval,
        granularity: parseFloat(selectedGranularity),
      });
    }
  }, [
    selectedMarket,
    selectedVariable,
    selectedAuditor,
    selectedInterval,
    selectedGranularity,
    intervalValue,
    dateFrom,
    dateTo,
  ]);

  const handleIntervalSelect = (e, v) => {
    if (typeof v === 'string') {
      switch (true) {
        case v.includes("-<N>-") && v.includes("absolute"): {
          setIntervalValue('1');
          setDateTo(moment().format(`${DATE_FORMAT_DASH}THH:mm`));
          setSelectedInterval(v);
          break
        }
        case v.includes("-<N>-"): {
          setIntervalValue('1');
          setSelectedInterval(v);
          break;
        }
        case v ==='absolute|<YYYY-MM-DDTHH:MM:SS>|<YYYY-MM-DDTHH:MM:SS>': {
          setDateFrom(moment().add(-1, 'day').format(`${DATE_FORMAT_DASH}THH:mm`));
          setDateTo(moment().format(`${DATE_FORMAT_DASH}THH:mm`));
          setSelectedInterval(v);
          break;
        }
        case v.includes("absolute"): {
          setDateTo(moment().format(`${DATE_FORMAT_DASH}THH:mm`));
          setSelectedInterval(v);
          break
        }
        default: {
          setSelectedInterval(v);
          break;
        }
      }
    } else {
      setSelectedInterval('');
    }
  };

  const renderDateField = (label, value, setValue) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={label}
          format="DD.MM.YYYY HH:mm"
          ampm={false}
          value={dayjs(value)}
          onChange={(value) =>
            setValue(dayjs(value).format('YYYY-MM-DDTHH:mm'))
          }
          sx={{ width: '100%' }}
        />
      </LocalizationProvider>
    )
  }

  const renderIntervalField = () => {
    return (
      <TextField
        type="number"
        label={`${
          selectedInterval.includes('min')
            ? 'Minutes'
            : selectedInterval.includes('hour')
            ? 'Hours'
            : 'Days'
        }`}
        onChange={(e) => setIntervalValue(`${e.target.value}`)}
        variant="outlined"
        value={intervalValue}
        InputProps={{
          inputProps: { min: 1 },
        }}
      />
    )
  }

  const renderExtraField = () => {
    if (selectedInterval.includes("-<N>-") && selectedInterval.includes("absolute")){
      return (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <FormControl
            className={classNames(
              classes.formControl,
              classes.fullWidth,
              classes.marginBottom
            )}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              {renderIntervalField()}
              {renderDateField("Date to", dateTo, setDateTo)}
            </div>
          </FormControl>
        </Grid>
      )
    } else if (
      selectedInterval ===
      'absolute|<YYYY-MM-DDTHH:MM:SS>|<YYYY-MM-DDTHH:MM:SS>'
    ) {
      return (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <FormControl
            className={classNames(
              classes.formControl,
              classes.fullWidth,
              classes.marginBottom
            )}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              {renderDateField("Date from", dateFrom, setDateFrom)}
              {renderDateField("Date to", dateTo, setDateTo)}
            </div>
          </FormControl>
        </Grid>
      );
    } else if (selectedInterval.includes("-<N>-")) {
      return (
        <Grid item xs={12} sm={6} md={2}>
          <FormControl
            className={classNames(
              classes.formControl,
              classes.fullWidth,
              classes.marginBottom
            )}
          >
            {renderIntervalField()}
          </FormControl>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={6} md={2}>
          <FormControl
            className={classNames(
              classes.formControl,
              classes.fullWidth,
              classes.marginBottom
            )}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              {renderDateField("Date to", dateTo, setDateTo)}
            </div>
          </FormControl>
        </Grid>
      )
    }
  };

  const handleRunAudit = async () => {
    const { marketId, variableId, auditor, interval, granularity } =
      customAuditData;
    const response = await fetch(
      `https://kube.sama.energy/gateway/api/markets/${marketId}/variables/${variableId}/audits`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          auditor,
          date_interval: interval,
          granularity,
          rule,
        }),
        ...security_fetch_params,
      }
    );
    if (response.status === 204) {
      alertify.success("Audit created!");
    } else {
      alertify.error("Error creating audit!");
    }
  };

  useEffect(() => {
    if (searchRef.current && variable.name === 'All') {
      const timeout = setTimeout(() => {
        set_loading(true);
        setPage(1);
        getSummaryAuditData(1, pageSize, market.name, search, source, date_from, date_to, auditor, importance, sorting);
      }, 1300)
      
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [search])

  const handleFilterChange = (value, field) => {
    set_loading(true);
    setPage(1);
    if (field === 'market') {
      const getVariables = async (market_id) => {
        const data = await getMarketVariables(market_id);
        if (data) {
          setFilterVariables(data);
          setSources([...new Set(data.map(el => el.source).sort((a, b) => a > b))]);
          alertify.success('Variable list is loaded!', 5);
        } else {
          setFilterVariables([]);
          setSources([]);
        }
      }
      
      if (!value || value.name === 'All') {
        set_filtered_variable({ name: 'All' });
        set_filtered_source('All');
        set_filtered_market(value ? value : { name: 'All' });
        getSummaryAuditData(1, pageSize, null, search ? search : null, null, date_from, date_to, auditor, importance, sorting);
        return;
      } else if (value.name !== 'All' && variable.name !== 'All') {
        set_filtered_variable({ name: 'All' });
      } 
      
      alertify.notify('Start loading variable list...');
      getVariables(value.id);
      getSummaryAuditData(1, pageSize, value.name, search ? search : null, source, date_from, date_to, auditor, importance, sorting);
      set_filtered_market(value);
    } else if (field === 'variable') {
      if (!value || value.name === 'All') {
        getSummaryAuditData(1, pageSize, market.name, null, source, date_from, date_to, auditor, importance, sorting);
      } else {
        getSummaryAuditData(1, pageSize, market.name, value.name, source, date_from, date_to, auditor, importance, sorting);
      }
      
      set_filtered_variable(value ? value : { name: 'All' });
      set_search('');
    } else if (field === 'dateFrom') {
      getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, source, value, date_to, auditor, importance, sorting);
      set_audit_date_from(value)
    } else if (field === 'dateTo') {
      getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, source, date_from, value, auditor, importance, sorting);
      set_audit_date_to(value);
    } else if (field === 'auditor') {
      getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, source, date_from, date_to, value, importance, sorting);
      set_filtered_auditor(value ? value : 'All');
    } else if (field === 'importance') {
      getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, source, date_from, date_to, auditor, value, sorting);
      set_filtered_importance(value ? value : 'All');
    } else {
      if (value === 'All') {
        getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, null, date_from, date_to, auditor, importance, sorting);
      } else {
        getSummaryAuditData(1, pageSize, market.name, search ? search : variable.name, value, date_from, date_to, auditor, importance, sorting);
      }
      
      set_filtered_source(value ? value : 'All');
    }
  }

  const tableHeads = ['Market', 'Variable Name', 'Auditor', 'Total', 'Details'];

  const makeColumns = (tableHeads) => {
    return tableHeads.map((head, id) => {
      return {
        title: head === 'Details' ? (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {helpModeActive && (
              <CustomTooltip title={tooltips?.details_column || ''}>
                <HelpOutline fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ) : head,
        field: head.toLowerCase().replace(' ', '_'),
        width: 100,
        sorting: id === 0 || id === 1 ? true : false,
      };
    });
  };

  const marketsOptions = [
    ...(sortedMarkets ? [{ name: 'All' }, ...sortedMarkets.map((el) => ({ name: el.name, id: el.id }))] : [])
  ];
  
  const variableOptions = [
    ...(filterVariables ? [{ name: 'All' }, ...filterVariables.sort((a, b) => a.name > b.name)] : [])
  ];

  const sourceOptions = [
    ...(sources ? ['All', ...sources] : [])
  ];

  const auditorsOprions = [
    ...(auditors ? ['All', ...auditors] : [])
  ];

  const importanceOptions = ['All', 'High', 'Medium', 'Low'];
  
  if (isLoggedIn) {
    return isLoading ? (
      <div className="loader">Loading...</div>
    ) : (
      <Grid container spacing={2}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classNames(classes.cardTitleWhite, classes.hidden)}>
              Title
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="market"
                    label="Market"
                    options={sortedMarkets}
                    value={selectedMarket}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, v) => setSelectedMarket(v)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="variable"
                    label="Variable"
                    options={variables}
                    value={selectedVariable}
                    disabled={!variables.length || !selectedMarket}
                    getOptionLabel={(option) => option.detailedName}
                    onChange={(_, v) => setSelectedVariable(v)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="auditor"
                    label="Auditor"
                    options={auditors}
                    value={selectedAuditor}
                    getOptionLabel={(option) => option}
                    onChange={(_, v) => setSelectedAuditor(v)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="interval"
                    label="Interval"
                    options={intervals}
                    value={selectedInterval}
                    getOptionLabel={(option) => option}
                    onChange={handleIntervalSelect}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              {selectedInterval &&
                (selectedInterval.includes("-<N>-") || 
                selectedInterval.includes("absolute")) &&
                renderExtraField()}
              <Grid item xs={12} sm={6} md={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="granularity"
                    label="Granularity"
                    options={['1', '0.5', '0.25']}
                    value={selectedGranularity}
                    getOptionLabel={(option) => option}
                    onChange={(_, v) => setSelectedGranularity(v)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    label="Rule:"
                    value={rule}
                    onChange={(e, v) => setRule(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} lg={2} xl={1}>
                <CustomButton
                  color="primary"
                  style={{ display: 'block', width: '100%' }}
                  disabled={!customAuditData}
                  onClick={handleRunAudit}
                >
                  Run Audit
                </CustomButton>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="primary">
            <h4 className={classNames(classes.cardTitleWhite)}>
              Market Data Audit
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="market_to_show"
                    label="Market to show"
                    options={marketsOptions}
                    value={market}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, v) => handleFilterChange(v, 'market')}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="source_to_show"
                    label="Source to show"
                    options={sourceOptions}
                    disabled={market.name === 'All'}
                    value={source}
                    getOptionLabel={(option) => option}
                    onChange={(_, v) => handleFilterChange(v, 'source')}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="variable_to_show"
                    label="Variable to show"
                    options={variableOptions}
                    disabled={market.name === 'All'}
                    value={variable}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, v) => handleFilterChange(v, 'variable')}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="auditor"
                    label="Auditor"
                    options={auditorsOprions}
                    value={auditor}
                    getOptionLabel={(option) => option}
                    onChange={(_, v) => handleFilterChange(v, 'auditor')}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.flexRow,
                    classes.marginBottom
                  )}
                >
                  <TextField 
                    type="date"
                    value={date_from}
                    onChange={(e) => handleFilterChange(e.target.value, 'dateFrom')}
                    variant="outlined"
                    inputProps={{
                      name: "dateFrom",
                      id: "dateFrom",
                    }}
                    label="Audits from:"
                    fullWidth={true}
                  />
                  <TextField 
                    type="date"
                    value={date_to}
                    onChange={(e) => handleFilterChange(e.target.value, 'dateTo')}
                    variant="outlined"
                    inputProps={{
                      name: "dateTo",
                      id: "dateTo",
                    }}
                    label="Audits to:"
                    fullWidth={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="importance"
                    label="Importance"
                    options={importanceOptions}
                    value={importance}
                    getOptionLabel={(option) => option}
                    onChange={(_, v) => handleFilterChange(v, 'importance')}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <>
                  <div className={classes.searchWrapper}>
                    <TextField 
                      id='search'
                      value={search}
                      onChange={(e) => {
                        set_search(e.target.value); 
                        set_filtered_variable({ name: 'All' });
                      }}
                      ref={searchRef}
                      type='search'
                      placeholder='Search' 
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <MaterialTable
                    columns={makeColumns(tableHeads)}
                    data={tableData}
                    onOrderCollectionChange={(orderBy) => {
                      if (orderBy.length) {
                        let newSorting = { ...sorting }

                        orderBy.forEach(el => {
                          // field before underscores if exists
                          const field = makeColumns(tableHeads)[el.orderBy].field.split('_')[0];
                          newSorting = {...newSorting, [field]: {
                            field: `${field}__name`,
                            direction: el.orderDirection,
                          }}
                        })

                        if (orderBy.length === 1) {
                          const field = makeColumns(tableHeads)[orderBy[0].orderBy].field.split('_')[0];
                          const key = Object.keys(sorting).filter(el => el !== field);
                          newSorting = {...newSorting, [key]: null}
                        }
                        set_loading(true);
                        setSorting(newSorting);
                        getSummaryAuditData(
                          page, 
                          pageSize, 
                          market.name, 
                          search ? search : variable.name, 
                          source, 
                          date_from,
                          date_to, 
                          auditor, 
                          importance, 
                          newSorting
                        );
                      } else {
                        set_loading(true);
                        setSorting({ market: null, variable: null });
                        getSummaryAuditData(
                          page, 
                          pageSize, 
                          market.name, 
                          search ? search : variable.name, 
                          source, 
                          date_from,
                          date_to, 
                          auditor, 
                          importance, 
                          null
                        );
                      }
                    }}
                    isLoading={loading}
                    options={{
                      toolbar: false,
                      tableLayout: 'fixed',
                      maxBodyHeight: window.innerHeight - 280,
                      headerStyle: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        color: primaryColor,
                        fontSize: '1em',
                        padding: '12px 0',
                        textAlign: 'center',
                        fontWeight: 500,
                      },
                      cellStyle: {
                        textAlign: 'center',
                      },
                      emptyRowsWhenPaging: false,
                      draggable: false,
                      pageSizeOptions: [25, 50, 100],
                      pageSize: pageSize,
                      sorting: true,
                      multiSort: true,
                      serverSide: true,
                      maxColumnSort: 2,
                    }}
                    components={{
                      Pagination: (props) => {
                        return (
                          <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            page={page - 1}
                            rowsPerPage={pageSize}
                            count={summary.total}
                            onPageChange={(e, num) => {
                              setPage(num + 1);
                              getSummaryAuditData(
                                num + 1, 
                                pageSize, 
                                market.name, 
                                search ? search : variable.name, 
                                source, 
                                date_from, 
                                date_to,
                                auditor, 
                                importance, 
                                sorting
                              );
                            }}
                            onRowsPerPageChange={(e) => {
                              setPageSize(e.target.value);
                              getSummaryAuditData(
                                page, 
                                e.target.value, 
                                market.name, 
                                search ? search : variable.name, 
                                source, 
                                date_from, 
                                date_to,
                                auditor, 
                                importance, 
                                sorting
                              );
                            }}
                          />
                        );
                      },
                    }}
                  />
                </>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedAuditData = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditData);
export default withStyles(helper.styles)(ConnectedAuditData);
