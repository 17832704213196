import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Chip,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import { HelpOutline, ReportProblemRounded } from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { DATE_FORMAT_DASH, DATE_FORMAT } from 'constants/general';

import { getPNLAnalysisData, get_markets_data } from 'actions';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CustomButton from 'components/CustomButtons/Button';
import 'moment-timezone';
import NoData from 'components/NoData';
import LoginPage from 'views/Login/Oops';
import * as helper from './PNLAnalysisHelper';
import { downloadParks } from 'actions';
import jsPDF from 'jspdf';
import localForage from 'localforage';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  isLoading: state.pnlAnalysis.loading,
  parks: state.pnlAnalysis.parks,
  filterStrategies: state.pnlAnalysis.filter.strategies,
  data: state.pnlAnalysis.strategiesData,
  error: state.pnlAnalysis.error,
  mainMarket: state.conn.mainMarket,
  marketsCountries: state.markets.markets,
  apiLabel: state.conn.label,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (data) => dispatch(getPNLAnalysisData(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

const markers = [
  { name: 'Profit', value: 'totalPnlFormatted', label: 'Profit' },
  { name: 'Profit/MWh', value: 'avgPnlMWh', label: 'Profit/MWh' },
  { name: 'Daily accuracy', value: 'accuracyDay', label: 'Daily accuracy' },
  { name: 'Max drawdown', value: 'maxDrawDown', label: 'Max drawdown' },
  { name: 'Sharpe ratio', value: 'sharpeRatio', label: 'Sharpe ratio' },
];

const parksToShowOpt = ["1", "3", "5"];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  overflow: 'scroll',
  height: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const cardKpi = (currency) => [
  {
    name: 'PNL',
    value: 'totalPnlFormatted',
    label: 'Profit',
    unit: `${currency}`,
  },
  {
    name: 'Max. volume',
    value: 'maxTradedVolume',
    label: 'Max. volume',
    unit: 'MWh',
  },
  {
    name: 'Total Traded volume',
    value: 'totalEnergy',
    label: 'Total Traded volume',
    unit: 'MWh',
  },
  {
    name: 'Profit/MWh',
    value: 'avgPnlMWh',
    label: 'Profit/MWh',
    unit: `${currency}/MWh`,
  },
  {
    name: 'Sharpe ratio',
    value: 'sharpeRatio',
    label: 'Sharpe ratio',
    unit: '',
  },
  {
    name: 'Max daily PNL',
    value: 'maxPnl',
    label: 'Max daily PNL',
    unit: `${currency}`,
  },
  {
    name: 'Min daily PNL',
    value: 'minPnl',
    label: 'Min daily PNL',
    unit: `${currency}`,
  },
  {
    name: 'PNL volatility',
    value: 'volaility',
    label: 'PNL volatility',
    unit: '%',
  },
  {
    name: 'Ratio Avg pos PNL to Avg neg PNL',
    value: 'ratioAvgPosNeg',
    label: 'Ratio Avg pos to Avg neg PNL',
    unit: '',
  },
  {
    name: 'Daily accuracy',
    value: 'accuracyDay',
    label: 'Daily accuracy',
    unit: '%',
  },
  {
    name: 'Max drawdown',
    value: 'maxDrawDown',
    label: 'Max drawdown',
    unit: '%',
  },
];

const bestPark = (parksData, showParks) => {
  let bestPark = [];
  for (let i = 0; i < parksData.length; i++) {
    if (showParks === '1') {
      if (parksData[i].totalEnergy !== 0) {
        bestPark.push(parksData[i]);
        break;
      }
    } else {
      if (bestPark.length < showParks && parksData[i].totalEnergy !== 0) {
        bestPark.push(parksData[i]);
      } else break
    }
  }

  if (bestPark.length) {
    return bestPark;
  } else {
    return [parksData[0]];
  }
};

const Profit = ({
  classes,
  isLoggedIn,
  isLoading,
  data,
  fetchData,
  error,
  parks,
  mainMarket,
  getMarkets,
  marketsCountries,
  apiLabel,
  helpModeActive,
  tooltips
}) => {
  const downloadLink = useRef();
  const [dateFrom, setDateFrom] = useState(helper.INITIAL_DATE_FROM);
  const [dateTo, setDateTo] = useState(helper.INITIAL_DATE_TO);
  const [daysBack, setDaysBack] = useState('');
  const [contracts, setContracts] = useState([
    helper.CONTRACTS[0],
    helper.CONTRACTS[1],
    helper.CONTRACTS[2],
  ]);
  const [strategies, setStrategies] = useState(["All"]);
  const [parksToShow, setParksToShow] = useState(parksToShowOpt[0]);
  const [markets, setMarkets] = useState([]);
  const [allParks, setAllParks] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [strategyParks, setStrategyParks] = useState({});
  const [sortedParks, setSortedParks] = useState({});
  const [keyword, setKeyword] = useState({
    name: helper.MEASURE_PROFIT_MWH,
    value: 'avgPnlMWh',
  });
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [currency, setCurrency] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState();
  const [options, setOptions] = useState([]); 

  HighchartsExporting(Highcharts);
  HighchartsExportingData(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');

  // Get market values from loaded parks
  const allMarkets = useMemo(() => {
    const uniqueMarkets = [...new Set(allParks.map((p) => p.market))];
    if (mainMarket) setMarkets([mainMarket]);
    else if (uniqueMarkets.length) setMarkets(uniqueMarkets);
    return uniqueMarkets;
  }, [allParks, mainMarket]);

  // Filter parks depending on selected market
  const filteredParks = useMemo(
    () =>
      allParks.filter((p) => {
        setSortedParks({});
        return markets.includes(p.market);
      }),
    [allParks, markets]
  );

  const sortParks = useCallback(() => {
    let obj = {};
    Object.keys(strategyParks).forEach((strategy) => {
      let arr = [];
      strategyParks[strategy].forEach((park) => {
        kpiData.filter((el) => el.name === park.name && arr.push({...el, maxVolume: park.capacity}));
      });
      if(keyword.value === 'maxDrawDown')
        arr.sort((a, b) => (b[keyword.value] < a[keyword.value] ? 1 : -1));
      else
        arr.sort((a, b) => (b[keyword.value] > a[keyword.value] ? 1 : -1));
      obj = { ...obj, [strategy]: arr };
    });
    setSortedParks(obj);
  }, [strategyParks, kpiData, keyword]);

  const getStrategiesParks = async () => {
    const data = await localForage.getItem("strategies_parks");

    if(!data || data.updated_at < moment().unix() - 43200) {
      let obj = {};
      helper.STRATEGIES.forEach((strategy) => {
        const onParksLoaded = (data) => {
          obj = { ...obj, [strategy]: data.data };
          if (Object.keys(obj).length === helper.STRATEGIES.length) {
            const dataObj = {
              data: obj,
              updated_at: moment().unix(),
            }
            localForage.setItem("strategies_parks", dataObj, (err) => {
             err && console.log(err);
            })
            setStrategyParks(obj);
          }
        };
        downloadParks({ strategies: [strategy], contracts }, onParksLoaded);
      });
    } else {
      setStrategyParks(data.data);
    }
  }

  useEffect(() => {
    if (
      kpiData.length &&
      Object.keys(strategyParks).length === helper.STRATEGIES.length
    ) {
      sortParks();
    } else {
      let obj = {};
      helper.STRATEGIES.map((strategy) => (obj = { ...obj, [strategy]: [] }));
      setSortedParks(obj);
    }
  }, [strategyParks, kpiData, keyword, sortParks]);

  useEffect(() => {
    const onLoad = (data) => setAllParks(data.data);
    downloadParks({ isAllParks: false }, onLoad);
    getMarkets();
    getStrategiesParks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContractsData = (cont) => {
    let obj = {};
    helper.STRATEGIES.forEach((strategy) => {
      const onParksLoaded = (data) => {
        obj = { ...obj, [strategy]: data.data };
        setStrategyParks(obj);
      };
      downloadParks({ strategies: [strategy], cont }, onParksLoaded);
    });
  }

  useEffect(() => {
    if (parks.length) {
      if (Object.keys(strategyParks).length === 0 && !allParks.length) {
        setAllParks(parks);
      }
    }
  }, [parks, strategyParks, allParks]);

  useEffect(() => {
    if (markets.length === 1 && marketsCountries.length) {
      const market = marketsCountries.filter(
        (el) => el.name === markets[0]
      );
      const currency = market ? market[0].currency : 'EUR';
      setCurrency(currency);
    }
  }, [markets, marketsCountries]);

  // Load data
  const loadData = useCallback(
    (dateFrom, dateTo) => {
      if(dateFrom < moment('2010-01-01') || dateTo < moment('2010-01-01')) {
        alertify.error("It seems that From or To date is too low.");
        return;
      }
      return filteredParks.length &&
        markets.length &&
        fetchData({
          parks: filteredParks,
          dateFrom: dateFrom.format(DATE_FORMAT),
          dateTo: dateTo.format(DATE_FORMAT),
          preloading: false,
          key: 'top_strategies',
          periodKey: `top_strategies_${strategies.join().replaceAll(',','_')}_${markets.join().replaceAll(',','_')}_${contracts.join().replaceAll(',','_')}_${dateFrom.format(DATE_FORMAT)}_${dateTo.format(DATE_FORMAT)}_${apiLabel}`,
          period: dateTo.unix() - dateFrom.unix()
        })
    }, [markets, filteredParks, fetchData, apiLabel, strategies, contracts]
  );

  // Initial API call
  useEffect(() => {
    if (filteredParks.length) loadData(dateFrom, dateTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredParks]);

  useEffect(() => {
    if(marketsCountries.length) {
      const newData = JSON.parse(JSON.stringify(data));
      data.forEach((parkData, i) => {
        const [park] = filteredParks.filter(park => park.id === parkData.id);
        if (park) {
          const [market] = marketsCountries.filter(market => market.name === park.market);
          newData[i].market = market.name;
        }
      })
      
      const filteredData = newData.filter(data => markets.includes(data.market));
  
      const { rows } = helper.calculateOptsAndRows(
        filteredData,
        filteredParks,
        'Profit/MWh',
        'Profit'
      );
      return setKpiData(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filteredParks, marketsCountries]);

  useEffect(() => {
    if(selectedStrategy) {
      const ids = bestPark(sortedParks[selectedStrategy], parksToShow).map(park => park.id);
      const parksData = data.filter(parkData => ids.includes(parkData.id));

      const { optionsCumulative } = helper.calculateStrategiesData(
        parksData,
        bestPark(sortedParks[selectedStrategy], parksToShow),
        'Profit/MWh',
        'Profit'
      );

      setOptions(optionsCumulative);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedStrategy, parksToShow, data])

  const handleChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (!value) return;

      const newDate = moment(value);
      // dateFrom can't be more than dateTo
      if (name === 'dateFrom' && newDate < dateTo) {
        setDateFrom(newDate);
        // dateTo can't be less than dateFrom
      } else if (name === 'dateTo' && newDate > dateFrom) {
        setDateTo(newDate);
      }
      setDaysBack('');
    },
    [dateFrom, setDateFrom, dateTo, setDateTo]
  );

  const handleAxesChange = useCallback(
    (e, v) => {
      setKeyword({ name: e.target.name, value: v });
    },
    [setKeyword]
  );

  const handleDaysBackChange = useCallback(
    (e) => {
      const value = e.target.value;
      setDaysBack(value);
      const diff = moment().diff(moment(dateTo), 'days');
      setDateFrom(
        moment().add(`-${value ? Number(value) + Number(diff) - 1 : 7}`, 'days')
      );
    },
    [setDaysBack, setDateFrom, dateTo]
  );

  const handleMarketsChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setMarkets(allMarkets);
      } else if (e.target.textContent === 'None') {
        setMarkets([]);
      } else {
        setMarkets(v);
      }
    },
    [setMarkets, allMarkets]
  );

  const handleStrategiesChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setStrategies(['All']);
      } else if (e.target.textContent === 'None') {
        setStrategies([]);
      } else {
        setStrategies(v);
      }
    }, [setStrategies]);
  
  const handleParksToShowChange = useCallback((e, v) => {
    setParksToShow(v);
  }, []);

  const handleContractsChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setContracts(helper.CONTRACTS);
        getStrategiesParks();
      } else if (e.target.textContent === 'None') {
        setContracts([]);
      } else {
        setContracts(v);
        getContractsData(v)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setContracts]
  );

  const loader = (
    <div className={classes.loadingContainer}>
      <div className="loader" alt="Loading report..."/>
    </div>
  );

  const renderedRadioBtn = () => {
    return markers.map((marker, id) => {
      return (
        <FormControlLabel
          value={marker.value}
          control={<Radio color="primary" />}
          label={marker.label}
          name={marker.name}
          key={id + marker.name}
        />
      );
    });
  };

  const handlePeriodClick = useCallback(
    (button) => {
      if (isLoading && button.order > selectedPeriod) return;

      const { dtFrom, dtTo } = helper.getRange(button.type, button.count);

      setSelectedPeriod(button.order);
      setDateFrom(dtFrom);
      setDateTo(dtTo);
      if (filteredParks.length) loadData(dtFrom, dtTo);
    },
    [isLoading, selectedPeriod, loadData, filteredParks]
  );

  const handleOpen = (strategy) => {
    setSelectedStrategy(strategy);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const rangeButtonsGroup = helper.rangeButtons.map((button) => {
    return (
      <Button
        key={button.order}
        color={button.order === selectedPeriod ? 'primary' : 'default'}
        onClick={() => handlePeriodClick(button)}
        variant={'contained'}
      >
        {button.text}
      </Button>
    );
  });

  const [keywordKpi] = cardKpi(currency).filter((kpi) => kpi.value === keyword.value);
  const strategiesToShow = strategies.includes("All") 
    ? helper.STRATEGIES 
    : strategies.includes("None") 
      ? [] 
      : strategies

  const content =
    !isLoading && Object.keys(sortedParks).length ? (
      <Grid container spacing={4} >
        {strategiesToShow.map((strategy, index) => {

          const length = bestPark(sortedParks[strategy], parksToShow).length;
          const headColSpan = length === 1 
            ? 2 
            : length === 2
              ? 3
              : length === 3
                ? 4
                : length === 4
                  ? 5
                  : 6
          const valueColSpan = length === 1
            ? 1 
            : length === 2
              ? 2
              : length === 3
                ? 3
                : length === 4
                  ? 4
                  : 5
          
          const smmd = parksToShow === '1' ? 6 : 12;
          const lg = parksToShow === '1' ? 4 : parksToShow === '3' ? 6 : 12

          if (!sortedParks[strategy].length) {
            return (
              <Grid
                item
                xs={12}
                sm={smmd}
                md={smmd}
                lg={lg}
                className={classes.cardContainer}
                key={index + strategy}
              >
                <Card className={classes.card} >
                  <CardHeader color={'warning'} stats icon>
                    <CardIcon color={'warning'}>
                      <h2>{strategy}</h2>
                    </CardIcon>
                    <p className={classes.cardCategory}>{''}</p>
                    <h3 className={classes.cardTitle}>
                      <small>{'No data'}</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {`Period: ${new Date(
                        dateFrom
                      ).toDateString()} - ${new Date(dateTo).toDateString()}`}
                    </div>
                  </CardFooter>
                </Card>
              </Grid>
            );
          }
          
          return (
              <Grid item xs={12} sm={smmd} md={smmd} lg={lg} key={index + strategy} onClick={() => handleOpen(strategy)}>
                <div className={classes.tableContainer}>
                  <TableContainer style={{ paddingBottom: '15px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={headColSpan}
                            style={{
                              display: 'block',
                              minHeight: '40px',
                              border: '0',
                              paddingLeft: '0',
                            }}
                          >
                            <h2 className={classes.strategyHead}>{strategy}</h2>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ padding: '5px' }}
                            className={classNames(
                              classes.cardCell,
                              classes.leftCellBorder
                            )}
                          >
                            {`KPIs: from ${
                              dateFrom.format('YYYY-MM-DD')
                            } to ${dateTo.format('YYYY-MM-DD')}`}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={valueColSpan}
                            style={{ padding: '5px' }}
                            className={classes.cardCell}
                          >
                            Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{ padding: '5px', color: '#777' }}
                            className={classNames(
                              classes.cardCell,
                              classes.leftCellBorder
                            )}
                          >
                            Park
                          </TableCell>
                          {bestPark(sortedParks[strategy], parksToShow).map((park, index) => {
                            return (
                              <TableCell
                                align="right"
                                style={{ padding: '5px', color: '#777' }}
                                className={classes.cardCell}
                                key={park.id}
                              >
                                {park.name}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              padding: '5px',
                              color: '#777',
                            }}
                            className={classNames(
                              classes.cardCell,
                              classes.leftCellBorder
                            )}
                          >
                            {keywordKpi.unit
                              ? `${keyword.name}, ${keywordKpi.unit}`
                              : keyword.name}
                          </TableCell>
                          {bestPark(sortedParks[strategy], parksToShow).map((park, index) => {
                            return (
                              <TableCell
                                align="right"
                                style={{ padding: '5px', color: '#777' }}
                                className={classes.cardCell}
                                key={park.id}
                              >
                                {`${park[keyword.value].toLocaleString()}`}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                        {cardKpi(currency).map(
                          (kpi, id) =>
                            kpi.value !== keyword.value && (
                              <TableRow key={kpi.name + id}>
                                <TableCell
                                  style={{ padding: '5px', color: '#777' }}
                                  className={classNames(
                                    classes.cardCell,
                                    classes.leftCellBorder
                                  )}
                                >
                                  {kpi.unit
                                    ? `${kpi.name}, ${kpi.unit}`
                                    : kpi.name}
                                </TableCell>
                                {bestPark(sortedParks[strategy], parksToShow).map((park, index) => {
                                  return (
                                    <TableCell
                                      align="right"
                                      style={{ padding: '5px', color: '#777' }}
                                      className={classes.cardCell}
                                      key={park.id}
                                    >
                                      {`${
                                          park[kpi.value] ===
                                          undefined
                                            ? 'N/A'
                                            : park[
                                                kpi.value
                                              ].toLocaleString()
                                        }`}
                                    </TableCell>
                                  )
                                })}
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
          );
        })}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpen}>
            <Box sx={style}>
            <h4
                  className={classNames(
                    classes.cardTitleBlack,
                    classes.textColor
                  )}
                >
                  Accumulated Daily PnL
                </h4>
                <div className={classes.marginBottomL}>
                  <div 
                    className={classes.marginBottomL}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"stockChart"}
                      options={options}
                    />
                  </div>
                </div>
            </Box>
          </Fade>
        </Modal>
      </Grid>
    ) : (
      loader
    );

  const exportToExcel = () => {
    const divider = '<table><tr></tr><tr></tr><tr></tr><tr></tr></table>';
    
    const processTableBeforeExport = (html) => {
      const processedHtml = html.cloneNode(true);
      let cells = [...processedHtml.querySelectorAll('tbody > tr >td:not(:first-child)')].slice(1);
      cells.forEach(cell => cell.textContent = `${cell.textContent}`.replace(/\s/g,'').replace(',','.'));
      return processedHtml.outerHTML;
    };

    const tables = document.getElementsByTagName("table");

    let processedTables = '';
    [...tables].forEach((table) => {
      processedTables += `${processTableBeforeExport(table)}${divider}`;
    });
    const csvData = `data:application/csv;charset=utf-8,${
      encodeURIComponent(processedTables)}`;
    downloadLink.current.setAttribute('href', csvData);
    downloadLink.current.click();
  };

  const exportToPdf = () => {
    const report = new jsPDF('portrait', 'pt', 'a4');
    const tables = document.querySelectorAll('table');
    tables.forEach(table => report.autoTable({html: table}));
    report.save('report.pdf');
  };

  const marketsOptions = [
    ...(allMarkets.length ? ['All', 'None'] : []),
    ...allMarkets,
  ];

  const strategiesOptions = [
    ...['All', 'None'],
    ...helper.STRATEGIES,
  ];

  const contractsOptions = [
    ...['All', 'None'],
    ...helper.CONTRACTS,
  ];

  const renderOptions = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip label={option} size="small" {...getTagProps({ index })} />
    ));

  if (!isLoggedIn) return <LoginPage />;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Profit & Loss Analysis</h4>
          </CardHeader>
          <CardBody
            className={classNames(
              classes.bgColor,
              classes.sticky,
              classes.textLeft
            )}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateFrom.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateFrom',
                      id: 'dateFrom',
                    }}
                    label="From:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateTo.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateTo',
                      id: 'dateTo',
                    }}
                    label="To:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.flexContainer}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                    <TextField
                      type="number"
                      label="Days range:"
                      variant="standard"
                      onChange={handleDaysBackChange}
                      value={daysBack}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                  </FormControl>
                  {helpModeActive && (
                    <CustomTooltip title={tooltips?.days_range || ''}>
                      <HelpOutline fontSize='small'/>
                    </CustomTooltip>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              className={`${classes.relative} ${classes.marginTopXs}`}
              style={{ marginBottom: '15px' }}
            >
              <FormLabel
                className={`${classes.block} ${classes.marginBottomXs}`}
              >
                <small>Select range:</small>
              </FormLabel>
              <div className={classNames(classes.flexContainer, classes.fromStart)}>
                <ButtonGroup size="small" aria-label="small button group">
                  {rangeButtonsGroup}
                </ButtonGroup>
                {helpModeActive && (
                  <CustomTooltip title={tooltips?.range || ''}>
                    <HelpOutline fontSize='small'/>
                  </CustomTooltip>
                )}
              </div>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="market"
                    label="Select markets:"
                    options={marketsOptions}
                    value={markets}
                    getOptionLabel={(option) => option}
                    onChange={handleMarketsChange}
                    multiple
                    filterSelectedOptions
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="parks"
                    label="Parks to show:"
                    options={parksToShowOpt}
                    value={parksToShow}
                    getOptionLabel={(option) => option}
                    onChange={handleParksToShowChange}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="strategies"
                    label="Select strategies:"
                    options={strategiesOptions}
                    value={strategies}
                    getOptionLabel={(option) => option}
                    onChange={handleStrategiesChange}
                    multiple
                    filterSelectedOptions
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="contracts"
                    label="Select contracs:"
                    options={contractsOptions}
                    value={contracts}
                    getOptionLabel={(option) => option}
                    onChange={handleContractsChange}
                    multiple
                    filterSelectedOptions
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.marginBottom}>
                  <FormLabel id="xAxis">
                    <small>Marker:</small>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="xAxis"
                    value={keyword.value}
                    name="xAxis"
                    onChange={handleAxesChange}
                  >
                    {renderedRadioBtn()}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} lg={3}>
                {/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
                <CustomButton
                  color="primary"
                  disabled={isLoading || !Object.keys(sortedParks).length}
                  onClick={exportToExcel}
                  tooltip={tooltips?.download_xls || ''}
                  helpModeActive={helpModeActive}
                >
                  Download xls
                </CustomButton>
                <a
                  id="id-plreport-toexcel"
                  role="button"
                  ref={downloadLink}
                  target="_blank"
                  download={`Top strategies.xls`}
                />
                {/* eslint-enable */}
              </Grid>
              <Grid item xs={6} lg={3}>
                <CustomButton
                  color="primary"
                  disabled={isLoading || !Object.keys(sortedParks).length}
                  onClick={exportToPdf}
                  tooltip={tooltips?.download_pdf || ''}
                  helpModeActive={helpModeActive}
                >
                  Download pdf
                </CustomButton>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={3} className={classes.marginTopXs}>
              <CustomButton
                color="primary"
                onClick={() => loadData(dateFrom, dateTo)}
                tooltip={tooltips?.calculate_button || ''}
                helpModeActive={helpModeActive}
              >
                Calculate
              </CustomButton>

              {error && (
                <Tooltip
                  title={<Typography fontSize={16}>Error: {error}</Typography>}
                  className={classes.error}
                >
                  <IconButton>
                    <ReportProblemRounded />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.container}>
          {filteredParks.length && markers.length && contracts.length ? (
            content
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card className={`${classes.bgColor} ${classes.noMargin}`}>
                  <CardBody className={classes.bgColor}>
                    <NoData />
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Profit.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  error: PropTypes.string,
  parks: PropTypes.array.isRequired,
};

Profit.defaultProps = {
  error: null,
};

const ConnectedProfit = connect(mapStateToProps, mapDispatchToProps)(Profit);
export default withStyles(helper.styles)(ConnectedProfit);
