import { RECEIVED_JOBS_DATA } from "constants/action-types";

import { LOADING_JOBS_DATA } from "constants/action-types";
import moment from 'moment';

const convertName = (name) => {
  if (name.includes('%7B')) {
    return decodeURIComponent(name);
  } else if (name.includes('for all parks')) {
    return name;
  } else {
    let obj = {};
    new URLSearchParams(name).forEach((value, key) => (obj[key] = value));
    return JSON.stringify(obj);
  }
}

const initialState = {
  jobs: [],
  tableHead: ['Name', 'Enqueued', 'Finished', 'Log', 'Warnings', 'Status'],
  tableData: [],
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_JOBS_DATA) {
     return { ...state, ...initialState };
  } else if (action.type === RECEIVED_JOBS_DATA) {
    if (typeof (action.jobs.data) !== 'undefined') {
      const {data} = action.jobs;
      const jobs = data.rqjobs.map(el => ({
        ...el,
        name: convertName(el.name),
      })).sort((a, b) => (
        moment(a.enqueued_at).unix() > moment(b.enqueued_at).unix() ? -1 : 1
      ));
      const schedule = data.scheduler.map(el => ({
        ...el,
        name: convertName(el.name),
        next_run_time: moment.tz(
          el.next_run_time, "YYYY-MM-DD HH:mm:ss z", "Europe/Berlin"
        ).tz('GMT').format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      })).sort((a, b) => (
        moment(a.next_run_time).unix() < moment(b.next_run_time).unix() ? -1 : 1
      ));
      return {
        ...state,
        jobs: data,
        schedule: schedule,
        tableHead: initialState.tableHead,
        tableData: jobs.map((x) => [
          x.id_hash,
          x.name,
          x.enqueued_at,
          x.ended_at,
          x.meta?.log,
          x.meta?.error_count,
          x.meta?.warning_messages,
          x.exc_info,
          x.status,
        ]),
        loading: false,
      };
    }
  }

  return state;
};
