import { FormControl, TextField, Grid, IconButton } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { stocksChartOptions, makeSeries, xAxisInCET } from 'variables/charts';

import { get_markets_data, buildAPIRequest, security_fetch_params } from 'actions';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import 'moment-timezone';
import * as helper from './PNLAnalysisHelper';
import { STRATEGIES } from 'constants/general';
import NoData from 'components/NoData';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { getMarketData, downloadParks } from 'utils/getDataMethods';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';

import localForage from "localforage";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import alertify from 'alertifyjs';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const styles = {
  ...checkboxAdnRadioStyle
}

const mapStateToProps = (state) => {
  return {
    markets: state.markets.markets,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: () => dispatch(get_markets_data()),
});

const InstanceReport = (props) => {
  const [instances, setInstances] = useState({});
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [selectedParks, setSelectedParks] = useState([]);
  const [parks, setParks] = useState({});
  const [parksTotal, setParksTotal] = useState(0);
  const [allParksData, setAllParksData] = useState(null);
  const [allParksInEUR, setAllParksInEUR] = useState(null);
  const [visibleLegends, setVisibleLegends] = useState()
  const [strategies, setStrategies] = useState(null);
  const [strategiesData, setStrategiesData] = useState(null);
  const [strategiesTableData, setStrategiesTableData] = useState([]);
  const [instancesData, setInstancesData] = useState(null);
  const [instancesTableData, setInstancesTableData] = useState([]);
  const [marketsTableData, setMarketsTableData] = useState(null);
  const [parksTableData, setParksTableData] = useState([]);
  const [nonActiveParks, setNonActiveParks] = useState(false);

  const [parkOptions, setParkOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [dates, setDates] = useState({});
  const [parksFromStorage, setParksFromStorage] = useState(null);
  const [currencyRates, setCurrencyRates] = useState(null);

  const { classes, getMarkets, markets, conn, tooltips } = props;
  
  const dateFrom = moment()
    .dayOfYear(1)
    .toISOString()
    .split('T')[0]
    .split('-')
    .join('');
  const dateTo = moment()
    .add(-1, 'day')
    .toISOString()
    .split('T')[0]
    .split('-')
    .join('');

    useEffect(() => {
      const storage = localStorage.getItem('selectedParks')
        ? JSON.parse(localStorage.getItem('selectedParks'))
        : {};
      const APIS = conn.loginInstances;

      const instList = Object.keys(APIS)
        .filter((api) => APIS[api].reporting);

      // not using instances from Local Storage that is not in instance list
      Object.keys(storage).forEach((instance) => {
        if (!instList.includes(instance)) {
          delete storage[instance];
        }
      });

      setParksFromStorage(storage);
    }, []);

  const getParksAPIS = async () => {
    let parksObj = {};
    let stratsObj = {};
    const APIS = conn.loginInstances;

    const instList = Object.keys(APIS)
      .filter((api) => APIS[api].reporting);
    instList.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
    
    const allParks = instList.map(async (instance) => {
        const parksArr = await downloadParks(null, APIS[instance].API_URL, nonActiveParks);
        return parksArr ? parksArr.data : []
    });

    const allStrategies = instList.map(async (instance) => {
      const instStrategies = STRATEGIES.map(async (strategy) => {
        const parksArr = await downloadParks(strategy, APIS[instance].API_URL, nonActiveParks);
        return parksArr ? {[strategy]: parksArr.data} : {[strategy]: []};
      })
      const stratArr = await Promise.all(instStrategies);

      let strategiesObj = {};
      stratArr.forEach(strategyObj => {
        strategiesObj = {...strategiesObj, ...strategyObj}
      })

      return {[instance]: strategiesObj};
    })
    
    const allStrategiesArr = await Promise.all(allStrategies);
    allStrategiesArr.forEach(instanceObj => {
      stratsObj = {...stratsObj, ...instanceObj}
    })

    const parkInstancesArr = await Promise.all(allParks);

    // sort parks in instance by name
    parkInstancesArr.forEach((parksArr, id) => {
      parkInstancesArr[id] = parksArr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    });

    instList.forEach((instance, id) => {
      parksObj[instance] = parkInstancesArr[id]
    })

    const instanceObj = {};
    Object.keys(parksObj).forEach((instance) => {
      instanceObj[instance] = {};
    })
    setStrategies(stratsObj);
    setInstances(instanceObj)
    setParks(parksObj)
  }

  const getParksData = async (instanceUrl, parkId, dateFrom, dateTo) => {
    const localforageParkDataString = `data_${instanceUrl}_${parkId}_${dateFrom}-${dateTo}`

    const data = await localForage.getItem(localforageParkDataString);

    if(!data) {
      const [, headers] = buildAPIRequest();
      const response = await fetch(
        `${instanceUrl}/api/windparks/${parkId}/pl_interval/${dateFrom}/${dateTo}?noID=0&forceReCalculate=0`,
        { method: 'GET', headers, ...security_fetch_params }
      );
      if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
        alertify.error("Response error");
      }
      const responseJson = await response.json();
      if (responseJson.error) {
        alertify.error("Response error");
        console.log(responseJson.error);
        return
      }
      responseJson.updated_at = moment().unix();
      await localForage.setItem(localforageParkDataString, responseJson, (err) => {
        err && console.log(err);
      });
      return responseJson;
    }
    return data;
  }

  const getSeriesesData = (allParksInEUR, visibleLegends) => {
    const parksInEUR = Object.keys(allParksInEUR).map(instance => {
      return Object.keys(allParksInEUR[instance]).map(park => {
        return allParksInEUR[instance][park];
      })
    }).flat();
    ;
    const opts = parksInEUR.map(park => {
      const parkDataSortByDate = [...park.data];
      parkDataSortByDate.sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1);
      let cum_profit = 0;
      const pl_data_optimised_cumulative = [];
      for (let i = 0; i < parkDataSortByDate.length; i++) {
          cum_profit += parkDataSortByDate[i].added_value_eur;
          pl_data_optimised_cumulative.push([
              moment(parkDataSortByDate[i].date).unix() * 1000,
              cum_profit,
          ]);
      }

      return {
        name: `${park.instance}: ${park.name}`,
        cumulative: pl_data_optimised_cumulative,
      }
    })

    const serieses = opts.map((el, ind) =>
        makeSeries(
            el.name,
            el.cumulative.map((val) => [val[0], val[1]]),
            { valueDecimals: 2, colorIndex: ind, visible: true }
        )
    );

    const serLen = serieses.length;
    if (serLen) {
        //Show only first 10 curves
        // for (let i = 0; i < (serLen < helper.CURVES_SHOW ? serLen : helper.CURVES_SHOW); i++) {
        //     serieses[i].visible = true;
        //     tempSerieses.push(serieses[i]);
        // }
    }

    if(visibleLegends === true) {
      serieses.forEach((el) => {
          el.visible = true;
      });
    } else if(visibleLegends === false) {
        serieses.forEach((el) => {
            el.visible = false;
        });
    }

    const optionsCumulative = {
      ...stocksChartOptions({ filename: `Daily P&L Accumulated ` }),
      yAxis: [
          {
              title: {
                  text: `Profit(Loss), EUR`,
              },
          },
          {
              title: {
                  text: `Added Value, EUR`,
              },
              opposite: true,
          },
      ],
      tooltip: {
        borderColor: '#2c3e50',
        shared: true,
        formatter: function (tooltip) {
          const points = this.points.sort((a,b) => a.y > b.y ? -1 : 1).filter((el,ind)=> ind < 10);
          return (tooltip.bodyFormatter(points).join(''))
        }
      },
      ...xAxisInCET,
      series: serieses,
    };

    //Change the height of the chart related on how much parks in it
    const defChartHeight = optionsCumulative.chart.height;
    optionsCumulative.chart.height =
        defChartHeight + serLen * 0.02 * defChartHeight;

    return optionsCumulative;
  }
  
  // Get markets and parks
  useEffect(() => {
    getMarkets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(Object.keys(conn.loginInstances).length)
      getParksAPIS();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[conn, nonActiveParks])

  //Form dates object
  useEffect(() => {
    const dtFrom = moment().dayOfYear(1);
    const dtTo = moment().add(-1, 'day');

    const getDatesObj = (dateFrom, dateTo) => {
      let dateObject = {};
      let currentDate = new Date(dateFrom);

      while (currentDate <= dateTo) {
        const dateKey = moment(currentDate).format("YYYY-MM-DD");
        dateObject[dateKey] = undefined;
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateObject;
    };

    let obj = getDatesObj(dtFrom, dtTo);
    setDates(obj);
  }, []);

  // Get all parks data for 1 year and total number of parks
  useEffect(() => {
    if (
      selectedParks.length && markets.length
    ) {
      let obj = {};
      let newObj = {};
      const APIS = conn.loginInstances;

      selectedInstances.forEach((instance) => newObj = { ...newObj, [instance]: {} });

      selectedParks.map(async (park) => {
        const instanceUrl = APIS[park.instance].API_URL;
        const market = markets.filter(
          (market) => market.name === park.market
        )[0];
        const data = await getParksData(instanceUrl, park.id, dateFrom, dateTo);
        obj = { ...obj, [`${park.instance}: ${park.park}`]: data ? data.data : [] };

        newObj = {
          ...newObj,
          [park.instance]: {
            ...newObj[park.instance],
            [park.park]: {
              name: park.park,
              park_id: park.id,
              market: market.name,
              market_id: market.id,
              currency: market.currency,
              data: data ? data.data : [],
            },
          },
        };
        let newObjParksCount = 0;
        if(Object.keys(newObj).length)
          Object.keys(newObj).forEach(instance => {
            newObjParksCount += Object.keys(newObj[instance]).length
          })
        if (
          Object.keys(newObj).length === selectedInstances.length &&
          newObjParksCount === selectedParks.length
        ) {
          // Sort parks and instances by name
          let newObjResorted = {};

          selectedInstances.forEach((instance) => newObjResorted = { ...newObjResorted, [instance]: {} })

          selectedParks.forEach(park => {
            newObjResorted = {
              ...newObjResorted,
              [park.instance]: {
                ...newObjResorted[park.instance],
                [park.park]: newObj[park.instance][park.park]
              }
            }
          });
          const sortObj = {};
          Object.keys(newObjResorted).sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).forEach(key => {
            sortObj[key] = newObjResorted[key];
          }
          );
          setAllParksData(sortObj);
        }
      });
      setParksTotal(selectedParks.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedParks, markets
  ]);

  //Get exchange rates for not EUR markets
  useEffect(() => {
    if (
      allParksData &&
      Object.keys(allParksData).length === selectedInstances.length &&
      Object.keys(allParksData)
        .map((instance) => Object.keys(allParksData[instance]))
        .flat().length === parksTotal
    ) {
      const dtFrom = moment().dayOfYear(1).add(-3, 'days');
      const dtTo = moment().add(-1, 'day');

      let newParksData = JSON.parse(JSON.stringify(allParksData));
      const uniqueCurrences = [];
      Object.keys(newParksData).forEach((instance) => {
        Object.keys(newParksData[instance]).forEach((park) => {
          if(newParksData[instance][park].currency !== 'EUR' && 
            !uniqueCurrences.find(el=>el.currency === newParksData[instance][park].currency))
            uniqueCurrences.push({
              'currency': newParksData[instance][park].currency,
              'marketId': newParksData[instance][park].market_id
            });
        });
      });
     
      const exchangeRates = {};
      const getExchangeRates = async (market, index) => {
        let daysRate = { ...dates };
        const data = await getMarketData(
          `${market.marketId}`,
          `ecb.europa.eu/EUR${market.currency} rate`,
          dtFrom,
          dtTo
        );
       
        if (data.data && data.data.length) {
          data.data[0].forEach((date, index) => {
            daysRate[moment(date[0]).format("YYYY-MM-DD")] =
              date[1];
          });
          helper.fillUndefinedValues(daysRate);

          exchangeRates[market.currency] = daysRate;
          
          if (uniqueCurrences.length && Object.keys(exchangeRates).length === uniqueCurrences.length) {
            setCurrencyRates(exchangeRates);
          }
        }

      }

      uniqueCurrences.forEach((el,index) => {
        el.currency === 'JPY'
          ? getExchangeRates({ marketId: 67, currency: 'JPY' }, index)
          : getExchangeRates(el, index);
      });

      if (!uniqueCurrences.length) setCurrencyRates({})
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allParksData])

  // Tranform all parks to EUR
  const getDataInEUR = async () => {
    let newParksData = JSON.parse(JSON.stringify(allParksData));
    
    Object.keys(newParksData).forEach((instance) => {
      Object.keys(newParksData[instance]).forEach(async (park) => {
        newParksData[instance][park].instance = instance;
        const currency = newParksData[instance][park].currency;
        newParksData[instance][park].data.forEach((data) => {
          const currencyRateForDay = currencyRates?.[currency]?.[data.date] || currencyRates?.[currency]?.[moment(data.date).subtract(1, 'days').format('YYYY-MM-DD')] || currencyRates?.[currency]?.[moment(data.date).add(1, 'days').format('YYYY-MM-DD')];
          data.added_value_eur = currency !== 'EUR' && currencyRates && currencyRates[currency] ? Number(
            (data.added_value / currencyRateForDay).toFixed(2)
          ) : data.added_value;
        });
      });
    });
    setAllParksInEUR(newParksData);
  };

  useEffect(() => {
    if (
      allParksData &&
      Object.keys(allParksData).length === selectedInstances.length &&
      Object.keys(allParksData)
        .map((instance) => Object.keys(allParksData[instance]))
        .flat().length === parksTotal &&
      currencyRates
    ) {
      getDataInEUR();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allParksData, currencyRates]);

  useEffect(() => {
    if (allParksInEUR && strategies) {
      // if today is first day of month, get data for previous month
      const month =
        moment().format('DD') === '01'
          ? moment().add(-1, 'months').toISOString().split('T')[0]
          : moment().date(1).toISOString().split('T')[0];
      let obj = {};
      
      Object.keys(allParksInEUR).forEach((instance) => {
        Object.keys(allParksInEUR[instance]).length 
          ? Object.keys(allParksInEUR[instance]).forEach((park) => {
            if (allParksInEUR[instance][park].data.length) {
              const yearTotal = allParksInEUR[instance][park].data.reduce(
                (acc, data) => acc + data.added_value_eur,
                0
              );
              const monthTotal = allParksInEUR[instance][park].data
                .filter((data) => data.date >= month)
                .reduce((acc, data) => acc + data.added_value_eur, 0);
    
              const dailyData = allParksInEUR[instance][park].data.filter(
                (data) => data.date.split('-').join('') === dateTo
              )
              const dailyTotal = dailyData.length ? dailyData[0].added_value_eur : 0;
    
              obj = {
                ...obj,
                [instance]: {
                  ...obj[instance],
                  [park]: {
                    name: allParksInEUR[instance][park].name,
                    instance,
                    market: allParksInEUR[instance][park].market,
                    market_id: allParksInEUR[instance][park].market_id,
                    park_id: allParksInEUR[instance][park].park_id,
                    year_total: Number(yearTotal.toFixed(2)),
                    month_total: Number(monthTotal.toFixed(2)),
                    daily_total: Number(dailyTotal.toFixed(2)),
                  },
                },
              }
            };
          }) : obj = {...obj, [instance]: {}};

        if(Object.keys(obj).length === Object.keys(allParksInEUR).length) {

          let stratObj = {};
          let objCopy = JSON.parse(JSON.stringify(obj));
          Object.keys(strategies).forEach((instance) => {
            if(obj[instance]) {
              Object.keys(strategies[instance]).forEach((strategy) => {
  
                const parksForStrategy = strategies[instance][strategy].length
                  ? strategies[instance][strategy].map((park) => {
                      delete objCopy[instance][park.name]
                      return obj[instance][park.name];
                    })
                  : [];
                stratObj[strategy] = {
                  ...stratObj[strategy],
                  [instance]: parksForStrategy,
                };
              });
            }
          });

          let other = {};
          Object.keys(objCopy).forEach((instance) => {
            if (Object.keys(objCopy[instance]).length) {
              Object.keys(objCopy[instance]).forEach((parkName) => {
                other[instance] =
                  other[instance] && other[instance].length
                    ? [...other[instance], objCopy[instance][parkName]]
                    : [objCopy[instance][parkName]];
              });
            } else {
              other[instance] = [];
            }
          });
          
          stratObj['other'] = {...other};
          
          setStrategiesData(stratObj);
          setInstancesData(obj);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allParksInEUR, strategies, instances]);

  const calcTotals = (arr) => {
    const y = arr.reduce((acc, data) => acc + data.year_total, 0);
    const m = arr.reduce((acc, data) => acc + data.month_total, 0);
    const d = arr.reduce((acc, data) => acc + data.daily_total, 0);
    return { y, m, d }
  }

  const getTableData = (arr) => {
    const data = arr.map(data => {
      return [data.name, data.year_total, data.month_total, data.daily_total]
    })
    const { y, m, d } = calcTotals(arr);
    return [...data, ['Total', y, m, d]]
  }

  useEffect(() => {
    if (
      instancesData && strategiesData && 
      Object.keys(instancesData).length === selectedInstances.length
    ) {
      let instancesObj = {};
      Object.keys(instancesData)
        .filter((instance) => selectedInstances.includes(instance))
        .forEach((instance) => {
          const parks = Object.keys(instancesData[instance])
            .filter((parkName) =>
              selectedParks.map((park) => park.park).includes(parkName)
            )
            .map((parkName) => {
              const park = instancesData[instance][parkName];

              return {
                instance: park.instance,
                name: parkName,
                year_total: park.year_total,
                month_total: park.month_total,
                daily_total: park.daily_total,
              };
            });
          instancesObj = {
            ...instancesObj,
            [instance]: [...parks],
          };
        });

      const parksArr = Object.keys(instancesData)
        .map((instance) =>
          Object.keys(instancesData[instance]).map(
            (park) => instancesData[instance][park]
          )
        )
        .flat();

      const marketsArr = markets.filter((market) => {
        const a = parksArr
          .filter((park) =>
            selectedParks.map((park) => park.id).includes(park.park_id)
          )
          .filter((park) => park.market === market.name);
        if (a.length) return market;
        else return null;
      });

      const instancesValues = Object.keys(instancesObj).map(instance => {
        const { y, m, d } = calcTotals(instancesObj[instance]);
        return {name: instance, year_total: Number(y.toFixed(2)), month_total: Number(m.toFixed(2)), daily_total: Number(d.toFixed(2))};
      })

      const parksValues = Object.keys(instancesObj)
      .map((instance) => {
        return instancesObj[instance].map((park) => ({
          name: `${park.instance}: ${park.name}`,
          year_total: park.year_total,
          month_total: park.month_total,
          daily_total: park.daily_total,
        }));
      })
      .flat();

      const marketsValues = marketsArr.map((market) => {
        const parks = parksArr
          .filter((park) =>
            selectedParks.map((park) => park.id).includes(park.park_id)
          )
          .filter((park) => park.market === market.name);
        const { y, m, d } = calcTotals(parks);
        
        return {name: market.name, year_total: Number(y.toFixed(2)), month_total: Number(m.toFixed(2)), daily_total: Number(d.toFixed(2))}
      });

      const strategiesValues = Object.keys(strategiesData).map(strategy => {
        const iTotals = Object.keys(strategiesData[strategy]).filter(instance => selectedInstances.includes(instance)).map(instance => {
          if(strategiesData[strategy][instance].length) {
            const filteredParks = strategiesData[strategy][instance].filter(park => park && selectedParks.map(park => park.park).includes(park.name))
            const { y, m, d } = calcTotals(filteredParks);
            return {year_total: y, month_total: m, daily_total: d}
          } else return null
        })
        const filtTotals = iTotals.filter(obj => obj);
        const { y, m, d } = calcTotals(filtTotals);

        if(strategy === 'DAIDvsIB') return {name: 'DA+IDvsIB', year_total: y, month_total: m, daily_total: d};
        if(strategy === 'DAvsIDIB') return {name: 'DAvsID+IB', year_total: y, month_total: m, daily_total: d};
        if(strategy === 'other') return {name: "Other", year_total: y, month_total: m, daily_total: d};

        return {name: strategy, year_total: Number(y.toFixed(2)), month_total: Number(m.toFixed(2)), daily_total: Number(d.toFixed(2))};
      })

      const instancesTableValues = getTableData(instancesValues);
      const parksTableValues = getTableData(parksValues);
      const marketsTableValues = getTableData(marketsValues);
      const strategiesTableValues = getTableData(strategiesValues);

      if(selectedInstances.length && selectedParks.length === Object.keys(instancesData).map(instance => Object.keys(instancesData[instance])).flat().length) {
        setStrategiesTableData(strategiesTableValues);
        setInstancesTableData(instancesTableValues);
        setParksTableData(parksTableValues);
        setMarketsTableData(marketsTableValues);
        setIsLoading(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instancesData, strategiesData, selectedInstances, selectedParks]);

  useEffect(() => {
    if (Object.keys(parks).length && selectedInstances.length && selectedInstances.every(el => Object.keys(parks).includes(el))) {
      const parksArr = selectedInstances
        .map((instance) => {
          if (!parks[instance].length) {
            alertify.error(`No parks for ${instance}!`);
          };

          return parks[instance].map((park) => ({
            name: `${instance}: ${park.name}`,
            id: park.id,
            park: park.name,
            instance,
            market: park.market,
          }));
        })
        .flat();

      setParkOptions(parksArr);
      setIsLoading(true)

      if(parksFromStorage && Object.keys(parksFromStorage).length) {
        const selected = selectedInstances
        .map((instance) => {
          if (parksFromStorage[instance]) {
            return parksFromStorage[instance].map((park) => ({
              name: `${instance}: ${park.name}`,
              id: park.id,
              park: park.name,
              instance,
            }));
          } else {
            return parks[instance]
          }
        })
        .flat();

        setSelectedParks(parksArr.filter(park => selected.map(park => park.id).includes(park.id)));
      } else {
        
        setSelectedParks(parksArr);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parks, selectedInstances]);

  useEffect(() => {
    if(parksFromStorage && Object.keys(parksFromStorage).length) {
      const instanceFromStorage = Object.keys(parksFromStorage);
      setSelectedInstances(instanceFromStorage);
    }
  },[parksFromStorage])

  const handleInstanceChange = (e, v) => {
    
    let obj = {};
    v.forEach(instance => {
      const arr = parksFromStorage[instance] ? parksFromStorage[instance] : parks[instance];
      obj = {...obj, [instance]: arr}
    })

    const sortedInstances = [...v].sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1); 
    setSelectedInstances(sortedInstances);
    if (!v.length) setSelectedParks([]);

    localStorage.setItem('selectedParks', JSON.stringify(obj));
  };

  const handleParkChange = (e, v) => {

    let obj = {};
    Object.keys(parks).forEach(instance => {
      const arr = parks[instance].filter(park => v.map(park => park.name).includes(`${instance}: ${park.name}`));
      if(arr.length)
        obj = {...obj, [instance]: arr};
    })
    
    // unique instances in selected parks
    const uniqueInstances = v.map(park => park.instance).filter((val,ind,arr) => arr.indexOf(val) === ind);
    if(uniqueInstances.length !== selectedInstances.length) {
      setSelectedInstances(uniqueInstances);
    }

    const sortedParks = [...v].sort((a,b) => {
      const splitA = a.name.split(':');
      const splitB = b.name.split(':');
      const comparison = splitA[0].localeCompare(splitB[0]);
      return comparison ? -1 : 1;
    });
    setSelectedParks(sortedParks);

    if(uniqueInstances.length !== selectedInstances.length) {
      setSelectedInstances(uniqueInstances);
    }
 
    localStorage.setItem('selectedParks', JSON.stringify(obj));
    
  };

  const handleChangeNonActiveParks = (event) => {
    setNonActiveParks(event.target.checked);
  }

  const instancesTableHead = [
    'Instance',
    'Current Year, EUR',
    'Current Month, EUR',
    'Daily Change, EUR',
  ];

  const parksTableHead = ['Park', 'Current Year, EUR', 'Current Month, EUR', 'Daily Change, EUR'];

  const marketsTableHead = [
    'Market',
    'Current Year, EUR',
    'Current Month, EUR',
    'Daily Change, EUR',
  ];

  const strategiesTableHead = [
    'Strategy',
    'Current Year, EUR',
    'Current Month, EUR',
    'Daily Change, EUR',
  ];

  const makeColumn = (headsArr) => {
    return headsArr.map((head) => {
      return {
        title: head,
        field: head.toLowerCase(),
        width: 80,
        sorting: false,
      };
    });
  };

  const makeRows = (dataArr) => {
    const rows = dataArr.map((rowArr, index) => {
      const obj = {};
      rowArr.map((data, index) => (typeof data === 'number' ? obj[index] = Number(data.toFixed(2)).toLocaleString() : obj[index] = data));
      obj['id'] = index;
      return obj;
    });
    return rows;
  };
  
  let arr = [];
  const renderTableRow = (data, lastIndex = null) => {
    const period = { ...data };
    delete period.tableData;
    delete period.id;
    if(data[0].split(": ").length > 1 && !arr.includes(data[0].split(": ")[0])) arr.push(data[0].split(": ")[0]);
    
    const index = data[0].split(": ").length > 1 && (arr.indexOf(data[0].split(": ")[0]) + 1) % 2 === 0 ? arr.indexOf(data[0].split(": ")[0]) : null
    
    return (
      <TableRow hover key={data.tableData.id} id={data.tableData.id} style={index ? {backgroundColor: "aliceblue"} : {}}>
        {Object.keys(period).map((key, index) => {
          return (
            <TableCell
              key={key + index}
              className={classes.fundamentalCell}
              align="center"
              style={
                lastIndex && data.tableData.id === lastIndex
                  ? { fontWeight: 'bold' }
                  : null
              }
            >
              {period[key]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderTable = (tableHead, tableData) => {
    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontSize: '1em',
      padding: '12px 0',
      textAlign: 'center',
      fontWeight: 500,
    };
    return (
      <Grid item xs={12}>
        <Card>
          <CardBody>
            <div className={classes.tableResponsive}>
              <MaterialTable
                columns={makeColumn(tableHead)}
                data={makeRows(tableData)}
                options={{
                  toolbar: false,
                  tableLayout: 'fixed',
                  paging: false,
                  maxBodyHeight: window.innerHeight - 280,
                  headerStyle: headerStyle,
                  emptyRowsWhenPaging: false,
                  pageSize: 6,
                  draggable: false,
                  search: false,
                }}
                components={{
                  Row: ({ data }) =>
                    renderTableRow(
                      data,
                      makeRows(tableData).length - 1
                    ),
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Grid>
    )
  }

  const renderChart = (data, title) => {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'column',
          },
          title: {
            text: title,
          },
          xAxis: {
            categories: [''],
          },
          yAxis: {
            title: {
              text: 'Added Value, EUR',
            },
          },
          series: [...data],
        }}
      />
    )
  }

  const renderColumnCharts = (data) => {
    if(data.length > 2) {
      const year = data.map(el => {
        if(el[0] === 'Total') return null
        return { name: el[0], data: [Number(el[1].toFixed(2))]}
      }).filter(el => el);
  
      const month = data.map(el => {
        if(el[0] === 'Total') return null
        return { name: el[0], data: [Number(el[2].toFixed(2))]}
      }).filter(el => el);
  
      return (
        <Grid container>
          <Grid item xs={6}>
            {renderChart(year, 'Current Year')}
          </Grid>
          <Grid item xs={6}>
            {renderChart(month, 'Current Month')}
          </Grid>
        </Grid>
      )
    } else return null
  }

  const content = () => {
    if (selectedInstances.length && !selectedParks.length) {
      return (
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardBody className={classes.bgColor}>
            <NoData />
          </CardBody>
        </Card>
      )
    }
    else if (
      parksTableData.length &&
      instancesTableData.length &&
      marketsTableData.length &&
      strategiesTableData.length &&
      !isLoading &&
      selectedInstances.length
    ) {
      const optionsCumulative = getSeriesesData(allParksInEUR, visibleLegends);
 
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card >
              <CardHeader color="primary">
                PnL per instance
              </CardHeader>
              <CardBody >
                {renderTable(instancesTableHead, instancesTableData)}
                {renderColumnCharts(instancesTableData)}
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card >
              <CardHeader color="primary">
                PnL per park
              </CardHeader>
              <CardBody >
                {renderTable(parksTableHead, parksTableData)}
                <Grid item xs={12}>
                  <Card>
                    <CardBody>
                      <h4
                        className={classNames(
                          classes.cardTitleBlack,
                          classes.textColor
                        )}
                      >
                        Accumulated Daily PnL
                      </h4>
                      <div className={classes.marginBottomL}>
                        <div
                          className={classes.marginBottomL}
                          onDoubleClick={() => {
                            optionsCumulative.series.length &&
                              setVisibleLegends(!visibleLegends);
                          }}
                        >
                          <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={optionsCumulative}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader color="primary">
                PnL per market
              </CardHeader>
              <CardBody >
                {renderTable(marketsTableHead, marketsTableData)}
                {renderColumnCharts(marketsTableData)}
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader color="primary">
                PnL per strategy
              </CardHeader>
              <CardBody >
                {renderTable(strategiesTableHead, strategiesTableData)}
                {renderColumnCharts(strategiesTableData)}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return <div className="loader" alt="Loading report..." />;
    }
  }; 
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.stickyHead}>
        <Card className={!isOpen ? classes.hidden : ""}>
          <CardHeader color="primary" className={!isOpen ? classes.visible : ""}>
            <h4
              className={classes.cardTitleWhite}
              style={{ position: 'relative' }}
            >
              Report settings
              <IconButton
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  display: 'inline-flex',
                  position: 'absolute',
                  right: '0',
                }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                )}
              </IconButton>
            </h4>
          </CardHeader>
          <CardBody style={!isOpen ? { display: 'none' } : undefined}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    multiple
                    id="instances"
                    options={Object.keys(instances)}
                    value={selectedInstances}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Instance" />
                    )}
                    onChange={handleInstanceChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    classes={{inputRoot: classes.autocompleteMaxHeight}}
                    disabled={selectedInstances.length ? false : true}
                    multiple
                    id="parks"
                    limitTags={2}
                    options={parkOptions}
                    value={selectedParks}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Park" />
                    )}
                    onChange={handleParkChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  control={
                    <CustomTooltip 
                      title={tooltips?.show_non_active || ''}
                      disableFocusListener={!props.helpModeActive}
                      disableHoverListener={!props.helpModeActive}
                      disableTouchListener={!props.helpModeActive}
                    >
                      <Checkbox
                        checked={nonActiveParks}
                        onChange={handleChangeNonActiveParks}
                        name="nonActiveParks"
                        className={classes.checkboxAdnRadioStyle}
                      />
                    </CustomTooltip>
                  }
                  label="Show non-active parks"
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.container}>
          {selectedInstances.length ? content() : null}
        </div>
      </Grid>
    </Grid>
  );
};

const ConnectedInstanceReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstanceReport);
export default withStyles(helper.styles, tableStyle, styles)(ConnectedInstanceReport);
