import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  buildAPIRequest,
  security_fetch_params,
  getDefaultPark,
  set_active_park,
  set_current_page,
  getModelsData,
  set_show_non_active_parks
} from 'actions/index';

import MaterialTable from '@material-table/core';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import ParkSelector from 'components/ParkSelector/ParkSelector';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Textsms } from '@material-ui/icons';

import CustomButton from 'components/CustomButtons/Button.jsx';

import { logout } from 'utils/auth';
import { downloadParks, getParkData } from 'utils/getDataMethods';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    activeParkState: state.conn.activePark,
    curPage: state.conn.curPage,
    showNonActive: state.conn.showNonActiveParks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  set_active_park: (activePark) => dispatch(set_active_park(activePark)),
  set_current_page: (activePark) => dispatch(set_current_page(activePark)),
  getModelsData: () => dispatch(getModelsData()),
  setShowNonActive: (data) => dispatch(set_show_non_active_parks(data)),
});

const ScheduleJobsList = ({
  conn,
  classes,
  activeParkState,
  set_active_park,
  showNonActive,
  setShowNonActive,
  helpModeActive,
  tooltips
}) => {
  const [scheduleJobs, setScheduleJobs] = useState([]);
  const [activePark, setActivePark] = useState(null);
  const [parks, setParks] = useState([]);
  const [isActivePark, setIsActivePark] = useState(true);

  const getParks = async () => {
    const parks = await downloadParks(null, null, showNonActive);
    setParks(parks.data);
  };

  const downloadParkData = async (activePark) => {
    const park = await getParkData({ API_NAME: conn.label, API_URL: conn.API_URL }, activePark)

    if (park && park.stages.optimization_job && !isActivePark) {
      setIsActivePark(true);
    } else if ((!park || !park.stages.optimization_job) && isActivePark) {
      setIsActivePark(false);
    }
  };

  useEffect(() => {
    getParks();

    if (!activeParkState) {
      const park = getDefaultPark() || { id: -1, capacity: null };
      const activePark = conn.parks.filter((item) => item.id === park.id)[0];
      setActivePark(activePark);

      if (activePark) {
        set_active_park(activePark);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNonActive]);

  const handleChange = (_, value) => {
    if (!value) return;
    if (value.id !== activeParkState.id) {
      set_active_park(value);
    }
  };

  const getScheduleJobs = () => {
    let [url, headers] = buildAPIRequest(
      `/api/windparks/${activeParkState.id}/schedule`
    );
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            if (data) setScheduleJobs(data);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activeParkState?.id) {
      setActivePark(activeParkState);
      getScheduleJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeParkState]);

  useEffect(() => {
    if (activePark && activePark.id !== -1) {
      downloadParkData(activePark);
    }
  }, [activePark])

  const optionsMatTable = {
    toolbar: false,
    tableLayout: 'fixed',
    paging: false,
    maxBodyHeight: window.innerHeight - 330,
    headerStyle: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    emptyRowsWhenPaging: false,
    pageSize: 6,
    draggable: false,
    search: false,
    cellStyle: {
      textAlign: 'center',
    },
  };

  const columnSettings = {
    sorting: false,
    width: 45,
    textAlign: 'center',
    headerStyle: {
      fontSize: '1em',
      padding: '1em 0',
      textAlign: 'center',
    },
  };
  const columnsSchedJobsNames = [
    {
      title: 'Job',
      field: 'job',
      ...columnSettings,
    },
    {
      title: 'Enabled',
      field: 'enabled',
      ...columnSettings,
    },
    {
      title: 'Edit',
      field: 'edit',
      ...columnSettings,
    },
    {
      title: 'Delete',
      field: 'delete',
      ...columnSettings,
    },
  ];

  const dataSchedJobs = [];
  scheduleJobs.forEach((item) => {
    const enabled = item.enabled ? 'Yes' : 'No';
    dataSchedJobs.push({
      id: item.id,
      job: item.job,
      enabled: enabled,
    });
  });

  const deleteItem = (parkId, schedJobId) => {
    let [url, headers] = buildAPIRequest(
      `/api/windparks/${parkId}/schedule/${schedJobId}`
    );
    fetch(url, {
      method: 'DELETE',
      headers: headers,
      ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          alertify.success('Schedule job has deleted', 5);
          getScheduleJobs();
        } else if (response.status === 401) {
          logout();
          return;
        } else {
          alertify.error(response.statusText, 5);
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };

  const renderData = (data) => {
    const dataTradCon = { ...data };
    delete dataTradCon.tableData;
    return (
      <TableRow>
        <TableCell align="center">{data.job}</TableCell>
        <TableCell align="center">{data.enabled}</TableCell>
        <TableCell align="center">
          <Link
            to={{
              pathname: '/schedjob-details',
              state: {
                schedJobId: data.id,
                parkId: activeParkState.id,
                typeOperation: 'edit',
              },
            }}
          >
            <Textsms />
          </Link>
        </TableCell>
        <TableCell align="center">
          <Textsms
            style={{ cursor: 'pointer' }}
            onClick={() => deleteItem(activeParkState.id, data.id)}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <div>
      <Grid container spacing={4}>
        <ParkSelector 
          country_code={""}
          country_name={""}
          options={parks}
          selected={activePark}
          handleChange={handleChange}
          capacity={activeParkState?.capacity === null
            ? 'N/A'
            : activeParkState?.capacity.toFixed(1)}
          nonActiveParks={showNonActive}
          handleChangeNonActiveParks={(_, v) => setShowNonActive(v)}
          helpModeActive={helpModeActive}
        />
        <Grid item xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Schedule Jobs</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                columns={columnsSchedJobsNames}
                data={dataSchedJobs}
                options={optionsMatTable}
                components={{
                  Row: ({ data }) => renderData(data),
                }}
              />
            </CardBody>
            <CardFooter>
              <Link
                to={{
                  pathname: '/schedjob-details',
                  state: {
                    parkId: activeParkState?.id,
                    typeOperation: 'new',
                  },
                }}
              >
                <CustomButton 
                  color="primary" 
                  helpModeActive={helpModeActive} 
                  tooltip={tooltips?.new_job_button || ''}
                  disabled={!isActivePark}
                >
                  New Schedule Job
                </CustomButton>
              </Link>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const ConnectedScheduleJobsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleJobsList);
export default withStyles(styles)(ConnectedScheduleJobsList);
