import React from 'react';
import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/icons';
import moment from 'moment';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const ProfitLossPeriod = ({
  classes,
  tableHead,
  tableData,
  park,
  marketId,
  isSkipIntraday,
  winHeight,
  currency,
  battery,
  withoutCache,
  helpModeActive,
  tooltips
}) => {
  const headerStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    color: primaryColor,
    fontSize: '1em',
    padding: '12px 0',
    textAlign: 'center',
    fontWeight: 500,
  };

  const columns = tableHead.map((head) => {
    if (head === 'Date') {
      return {
        title: head,
        field: head.toLowerCase(),
        width: 50,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
          color: primaryColor,
        },
        headerStyle: {
          left: '0px',
          zIndex: 3,
        },
      };
    } else if (head === 'PnL details'|| head === '') {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || ''}>
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 60,
        sorting: false,
      };
    } else if (head.includes('Total Optimised') || head.includes('Added value')) {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || ''}>
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 86,
        sorting: false,
      };
    } else {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || ''}>
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 100,
        sorting: false,
      };
    }
  });

  const renderTableRow = (data) => {
    const period = { ...data };
    delete period.tableData;
    delete period.id;

    return (
      <TableRow hover key={data.tableData.id} id={data.tableData.id}>
        {Object.keys(period).map((key) => {
          let cellClasses = '';

          if (
            tableHead[key].includes('Total Optimised') ||
            tableHead[key].includes('Total base')
          ) {
            if (!String(period[0]).includes('Total')) {
              cellClasses = classes.lightblueCell;
            }
            else {
              cellClasses = classes.boldCell;
            }            
          }

          if (
            tableHead[key] === 'Date' 
              || tableHead[key]?.includes('Added value') 
              || tableHead[key]?.includes('Customer PnL')
              || tableHead[key]?.includes('Alternative PnL')
          ) {
            if (String(period[0]).includes('Total')) {
              cellClasses = classes.boldCell;
            }
          }

          if (tableHead[key] === 'PnL details') {
            return (
              <TableCell
                align="center"
                key={data.tableData.id + Number(key)}
                className={`${classes.fundamentalCell}`}
              >
                <CustomTooltip
                  title={tooltips?.details || ''}
                  disableFocusListener={!helpModeActive}
                  disableHoverListener={!helpModeActive}
                  disableTouchListener={!helpModeActive}
                >
                  <Link
                    to={{
                      pathname: '/profitlossdaily',
                      state: {
                        data: period,
                        park,
                        isSkipIntraday,
                        marketId,
                        currency: currency,
                        battery,
                        withoutCache,
                      },
                    }}
                  >
                    {' '}
                    <Input />
                  </Link>
                </CustomTooltip>
              </TableCell>
            );
          } else {
            const firstCell =
              String(period[0]).includes('Total') || !period[0].includes('-')
                ? period[0]
                : moment(period[0]).format('DD MMM YYYY');
            const restCell =
              typeof period[key] === 'string'
                ? period[key]
                : window.numberWithCommas(period[key]?.toFixed(2));
            return (
              <TableCell
                className={`${
                  key === '0' ? `${classes.stickyCol}` : ''
                } ${cellClasses} ${classes.fundamentalCell}`}
                align="center"
                key={data.tableData.id + Number(key)}
              >
                {key === '0' ? firstCell : restCell}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  // need object data for material table
  const refData = tableData.map((data, index) => {
    const objData = {};
    data.forEach((element, i) => {
      objData[i] = element;
      objData['id'] = index;
    });
    return objData;
  });

  return (
    <div className={classes.tableResponsive}>
      <MaterialTable
        columns={columns}
        data={refData}
        options={{
          toolbar: false,
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: winHeight - 280,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
        }}
        components={{
          Row: ({ data }) => renderTableRow(data),
        }}
      />
    </div>
  );
};

ProfitLossPeriod.defaultProps = {
  tableHeaderColor: 'primary',
  park: PropTypes.object.isRequired,
};

ProfitLossPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(ProfitLossPeriod);
