/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import {filterRoutes} from 'routes/dashboard.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';

import image from 'assets/img/sidebar-5.jpg';
import color_logo from 'assets/img/color_logo.png';
import { connect } from 'react-redux';
import { set_uinfo, set_logged_in, setActiveAPI } from 'actions/index';
import { managePolicyRequest } from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';


const filterRoute = (x) => 
  x.hide == undefined || x.hide == false;

const SwitchRoutes = ({ routes, isLoggedIn, uinfo, helpModeActive, tooltips }) => {

  // main route for redirect if wrong url
  const firstPermitMenu = uinfo?.policy
    ? routes.filter(filterRoute)[0]?.path
    : '/loading';
  let filterRoutesFrom = routes;

  // when user is logged in, exclude login and loading pages from routes
  if (uinfo?.policy) {
    filterRoutesFrom = routes.filter(
      (prop) => prop.path !== '/login' && prop.path !== '/loading'
    );
  }
  return (
    <Switch>
      {filterRoutesFrom.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={firstPermitMenu} key={key} />;
        return (
          <Route
            path={prop.path}
            render={(props) => {
              const Component = prop.component;
              return (
                <Component 
                  {...props} 
                  helpModeActive={helpModeActive} 
                  tooltips={tooltips}
                  {...prop.path.includes('battery') ? {battery: true} : {}}
                />
              );
            }}
            key={key}
          />
        );
      })}

      {/* if user not logged in, redirect to login page */}
      {!isLoggedIn && <Redirect from={'/'} to={'/login'} />}
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    uinfo: state.login.uinfo,
    masterAlert: state.structured_alerts.masterAlert,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  set_uinfo: (data) => dispatch(set_uinfo(data)),
  set_logged_in: (data) => dispatch(set_logged_in(data)),
  setActiveAPI: (data) => dispatch(setActiveAPI(data)),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      helpModeActive: false,
      tooltips: null
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== '/maps';
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  async getTooltipsData(sheetName) {
    const sheetId = '1Z_walQwON7biZcgEfoMbLWepTJovLQcEPFAKlwSU8P0';
    const API_KEY = 'AIzaSyBsBYwm8RNnGKgfzF_BBiPZBfZ3YDE3MsA'

    try {
      const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/'${sheetName}'!A:Z?key=${API_KEY}`);
      const data = await response.json();
      if (data.values) {
        const obj = {}
        data.values.forEach((val, index) => {
          if (!val.length) return null;
  
          const value = val.slice(1)
          obj[val[0]] = obj[val[0]] ? [...obj[val[0]], value] : [value]
        })
        
        const dataObj = Object.fromEntries(Object.entries(obj).map(([pathname, dataArr]) => {
          return [pathname, Object.fromEntries(dataArr.map(el => {
            if (el.length > 2) {
              if (pathname === '/overrideda' && el[0] === 'browse') {
                return [
                  el[0],
                  <div>
                    {el[1]}
                    <ul style={{margin: '0', padding: '0 10px'}}>
                      {el.slice(2, el.length - 1).map((text, index) => <li key={index}>{text}</li>)}
                    </ul>
                    {el.pop()}
                  </div> 
                ]
              }
              
              return [
                el[0], 
                <div>
                  {el[1]}
                  <ul style={{margin: '0', padding: '0 20px'}}>
                    {el.slice(1).map((v, i) => <li key={i}>{v}</li>)}
                  </ul>
                </div>
              ]
            } else return el
          }))];
        }))
        
        this.setState({tooltips: dataObj});
      }
    } catch (err) {
      console.error('Error fetching data!', err)
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener('resize', this.resizeFunction);
    const uinfo = JSON.parse(sessionStorage.getItem('uinfo'));
    if (!this.props.uinfo && uinfo) {
      const login = uinfo?.data.user.login;
      const target = uinfo?.data.target.label;
      const ticket = uinfo?.data.ticket.ticket;
      const path = uinfo?.data.target.url;
      const url = `${path}?login=${login}&target=${target}&ticket=${ticket}`;

      // get policies if logged in
      fetch(url, {
        method: 'GET',
      })
        .then(managePolicyRequest)
        .catch((error) => {
          console.error('Account service login failed: fetch error: ', error);
        });
    }
    const helpModeStatus = JSON.parse(localStorage.getItem('helpModeActive'));
    if (helpModeStatus) {
      this.setState({helpModeActive: helpModeStatus.status})
    };
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }

    // Check for changes in props, find the current route, and load tooltip data for its group if needed.
    if (JSON.stringify(e) !== JSON.stringify(this.props)) {
      let currentRoute = filterRoutes(this.props.conn).find(el => el.path === this.props.location.pathname);

      // If the current route is a child, we search for the parent's group
      if (currentRoute && !currentRoute.group) {
        currentRoute = filterRoutes(this.props.conn).find(el => el.path === currentRoute.parent)
      }

      if (
        currentRoute?.group && 
        (!this.state.tooltips || !Object.keys(this.state.tooltips).includes(this.props.location.pathname))
      ) this.getTooltipsData(currentRoute.group)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }
  render() {

    // routes from policies
    let visible_items =
      this.props.uinfo &&
      this.props.uinfo.policy.hasOwnProperty('gui-oam-visible-items') &&
      this.props.uinfo.policy['gui-oam-visible-items'].length > 0
        ? this.props.uinfo.policy['gui-oam-visible-items'][0].obj
        : [];

    // filter routes using policies
    const filteredRoutes = () => {
      const objDashboardRoutes = {}; // obj of all routes from dashboardRoutes
      filterRoutes(this.props.conn).forEach((route) => {
        objDashboardRoutes[route.path] = route;
      });

      const objPermitRoutes = {}; // obj of routes that user has permission to see
      filterRoutes(this.props.conn).forEach((route) => {
        if (route.sidebarName === 'Loading' || 
          route.sidebarName === 'Login' ||
          (route.navbarName === 'Redirect' && visible_items.length) ||
          (route.sidebarName === 'Sidebar settings' && visible_items.length) ||
          (
            (visible_items.includes(route.path) ||
            visible_items.includes(route.parent) ||
            visible_items.includes('all')) 
          )
        ){
          objPermitRoutes[route.path] = route;
        }
      });
      
      const objVisibleRoutes = {};  // obj of routes that user set as visible
      const commonObjDashVisible = {};  // obj of elements from dashboardRoutes that are visible
      const prepareRoutes = []; // array of permitted routes and visible routes
      const filterObjPermitRoutes = Object.entries(objPermitRoutes).filter(item=>item[1].hide === false && item[0] !== '/permissions').map(el => el[0]);
      if(this.props.conn.visibleRoutes 
          && visible_items.length
          && this.props.conn.visibleRoutes?.map(item => item.path).sort().join(',') === filterObjPermitRoutes.sort().join(',')
          ) {

        this.props.conn.visibleRoutes.forEach((route) => {
          objVisibleRoutes[route.path] = route;
        });
        Object.keys(objVisibleRoutes).forEach((key) => {
          if(objVisibleRoutes[key].checked) {
            commonObjDashVisible[key] = objDashboardRoutes[key];
          }
        });
        Object.keys(commonObjDashVisible).forEach((key) => {
          prepareRoutes.push(commonObjDashVisible[key]);
        });
        Object.keys(objPermitRoutes).forEach((key) => {
          if(commonObjDashVisible[key] === undefined && objVisibleRoutes[key]?.checked === undefined ) {
            prepareRoutes.push(objPermitRoutes[key]);
          }
        });
      }
      else if( this.props.conn.visibleRoutes 
        && visible_items.length 
        && this.props.conn.visibleRoutes?.map(item => item.path).sort().join(',') !== filterObjPermitRoutes.sort().join(',')) {
          localStorage.clear()
          this.props.setActiveAPI({...this.props.conn,visibleRoutes:null});
      }
      else {
        Object.keys(objPermitRoutes).forEach((key) => {
          prepareRoutes.push(objPermitRoutes[key]);
        });
      }

      return prepareRoutes;
    };

    const { classes, ...rest } = this.props;

    return (
      <div className={classes.wrapper}>
        {this.props.location.pathname === "/login" && <canvas
          className={classes.canvas}
          id='world'
        ></canvas>}
        {this.props && this.props.isLoggedIn == true && this.props.uinfo ? (
          <Sidebar
            routes={filteredRoutes().filter(filterRoute)}
            logoText={<span className={classes.logo}>SAMA<span className={classes.asset}>Asset<sup className={classes.supText}>TM</sup></span></span>}
            logo={color_logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            {...rest}
          />
        ) : null}
        <div id='mainPanel' className={`${classes.mainPanel} ${classes.scrollbar} ${this.props.location.pathname === "/login" ? classes.loginPanel : ""}`} ref="mainPanel">
          {this.props && this.props.isLoggedIn == true && this.props.uinfo ? (
            <Header
              routes={filteredRoutes()}
              handleDrawerToggle={this.handleDrawerToggle}
              helpModeActive={this.state.helpModeActive}
              activateHelpMode={() => {
                localStorage.setItem('helpModeActive', JSON.stringify({status: !this.state.helpModeActive}));
                this.setState({ helpModeActive: !this.state.helpModeActive });
              }}
              description={this.state.tooltips?.[location.pathname]?.description || ''}
              {...rest}
            />
          ) : null}

          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={`${classes.content} ${this.props.location.pathname === "/login" ? classes.loginContent : ""}`}>
              <div className={classes.container}>
                <SwitchRoutes
                  routes={filteredRoutes()}
                  isLoggedIn={this.props.isLoggedIn}
                  uinfo={this.props.uinfo}
                  helpModeActive={this.state.helpModeActive}
                  tooltips={this.state.tooltips?.[location.pathname] || null}
                />
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <SwitchRoutes
                routes={filteredRoutes()}
                isLoggedIn={this.props.isLoggedIn}
                uinfo={this.props.uinfo}
              />
            </div>
          )}
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

const AppConnect = connect(mapStateToProps, mapDispatchToProps)(App);
export default withStyles(dashboardStyle)(AppConnect);


