import { FormControl, TextField, Grid, Tooltip } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CustomButton from 'components/CustomButtons/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import LoginPage from 'views/Login/Oops';

import {
  get_markets_data,
  getCategories,
  setCurrentAnalysMarket,
} from 'actions/index';

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

import { getVariables } from 'utils/getDataMethods';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  markets: state.markets.markets,
  categoriesDic: state.dictionaries.categoriesDic,
  conn: state.conn,
});

const mapDispatchToProps = (dispatch) => ({
  getMarkets: () => dispatch(get_markets_data()),
  getCategories: () => dispatch(getCategories()),
  setCurrentAnalysMarket: (curMarket) =>
    dispatch(setCurrentAnalysMarket(curMarket)),
});

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const DataAnalysis = ({
  isLoggedIn,
  classes,
  getMarkets,
  getCategories,
  setCurrentAnalysMarket,
  categoriesDic,
  markets,
  conn,
  helpModeActive,
  tooltips
}) => {
  const [variables, setVariables] = useState([]);
  const [variableDisplayData, setVariableDisplayData] = useState([]);
  useEffect(
    () => {
      if (isLoggedIn) {
        getMarkets();
        getCategories();
      }
    },
    // eslint-disable-next-line
    []
  );

  // Get variables for the current market
  const getVariablesMarket = async (curMar) => {
    if (!curMar) return;
    else {
      const marketId = curMar.id;
      const varPromisesArr = categoriesDic.categoriesDic.map((category) =>
        getVariables(marketId, category, conn.label)
      );
      const variablesAll = await Promise.all(varPromisesArr);
      const serVariables = variablesAll.map(
        (variables) => variables && serializeVariables(variables)
      );
      const allVariables = [];
      serVariables.forEach(
        (variables) => variables && allVariables.push(...variables)
      );
      allVariables.forEach((variable) => {
        variable.booleanValue = false;
      });

      // Check if variable is already selected, if yes, set booleanValue to true
      variableDisplayData
        .filter((el) => el.marketId === marketId)
        .forEach((el) => {
          allVariables[el.ind].booleanValue = true;
        });

      setVariables(allVariables);
    }
  };

  useEffect(() => {
    getVariablesMarket(conn.curAnalysMarket);
    // eslint-disable-next-line
  }, [conn.curAnalysMarket]);

  const handleChangeMarket = (event, value) => {
    setCurrentAnalysMarket(value);
  };

  const serializeVariables = (variables) => {
    if (!variables) return [];
    const variableObj = {};
    const types = Object.keys(variables);
    types.forEach((type) => {
      const sources = Object.keys(variables[type]);
      sources.forEach((source) => {
        const variablesArr = variables[type][source];
        variablesArr.forEach((variable) => {
          if (!variableObj.hasOwnProperty(variable)) {
            variableObj[variable] = {};
            variableObj[variable][type] = [source];
            variableObj[variable]['info'] = {
              type: [type],
              source: [source],
            };
          } else {
            if (!variableObj[variable].hasOwnProperty(type)) {
              variableObj[variable][type] = [source];
            } else {
              variableObj[variable][type].push(source);
            }
            if (!variableObj[variable]['info']['type'].includes(type))
              variableObj[variable]['info']['type'].push(type);
            if (!variableObj[variable]['info']['source'].includes(source))
              variableObj[variable]['info']['source'].push(source);
          }
        });
      });
    });
    const serializeVarArr = Object.keys(variableObj).map((variable) => ({
      variable,
      types: variableObj[variable]['info']['type'],
      sources: variableObj[variable]['info']['source'],
      variableExtended: variableObj[variable],
    }));
    return serializeVarArr;
  };

  const marketsNames = markets
    .filter((el) => el.parent_id)
    .map((el) => ({
      label: el.name,
      id: el.id,
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  //sort and move Germany and France to the top
  const germanIndex = marketsNames.findIndex((el) => el.label === 'Germany');
  const franceIndex = marketsNames.findIndex((el) => el.label === 'France');
  const sortedMarketsNames = [...marketsNames];
  sortedMarketsNames.unshift(marketsNames[franceIndex]);
  sortedMarketsNames.unshift(marketsNames[germanIndex]);
  sortedMarketsNames.splice(franceIndex + 2, 1);
  sortedMarketsNames.splice(germanIndex + 1, 1);

  const columns = [
    {
      title: 'Set',
      field: 'booleanValue',
      width: 50,
    },
    {
      title: 'Variable',
      field: 'variable',
      width: 200,
      sorting: false,
    },
    {
      title: 'Types',
      field: 'types',
      width: 100,
      sorting: false,
    },
    {
      title: 'Sources',
      field: 'sources',
      width: 100,
      sorting: false,
    },
  ];

  const renderData = (data) => {
    const dataVariables = { ...data };
    const index = data.tableData.id;
    delete dataVariables.tableData;

    return (
      <TableRow>
        <TableCell>
          <CustomTooltip 
            title={tooltips?.add_checkbox || 'Add to list'}
            disableFocusListener={!helpModeActive}
            disableHoverListener={!helpModeActive}
            disableTouchListener={!helpModeActive}
          >
            <input
              type="checkbox"
              checked={data.booleanValue}
              onChange={(e) => {
                const newVariables = JSON.parse(JSON.stringify(variables));
                newVariables[index].booleanValue = e.target.checked;
                const newVariableDisplayData = [...variableDisplayData];
                if (e.target.checked) {
                  newVariableDisplayData.push({
                    variable: data.variable,
                    types: data.types,
                    sources: data.sources,
                    variableExtended: data.variableExtended,
                    ind: index,
                    marketId: conn.curAnalysMarket.id,
                  });
                } else {
                  newVariableDisplayData.splice(
                    newVariableDisplayData.findIndex((el) => el.ind === index),
                    1
                  );
                }

                setVariables(newVariables);
                setVariableDisplayData(newVariableDisplayData);
              }}
            />
          </CustomTooltip>
        </TableCell>
        <TableCell>{data.variable}</TableCell>
        <TableCell>{data.types.join(',')}</TableCell>
        <TableCell>{data.sources.join(',')}</TableCell>
      </TableRow>
    );
  };

  if (!isLoggedIn) return <LoginPage />;

  const varTypes = {};
  const variablesExtend = {};
  variableDisplayData.forEach((el, i) => {
    varTypes[i] = el.types;
    variablesExtend[i] = el.variableExtended;
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Data analysis</h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classNames(classes.fullWidth)}>
                  <Autocomplete
                    disablePortal
                    id="var_market"
                    value={conn.curAnalysMarket || null}
                    options={sortedMarketsNames}
                    isOptionEqualToValue={((opt, val) => opt.label === val.label)}
                    renderInput={(params) => (
                      <TextField {...params} label="Markets" />
                    )}
                    onChange={handleChangeMarket}
                  />
                </FormControl>
              </Grid>
              {variableDisplayData.length ? (
                <Grid item xs={12} sm={6}>
                  <Link
                    to={{
                      pathname: '/data_analysis_details',
                      state: {
                        variable: variableDisplayData[0].variable,
                        variables: variableDisplayData.map((el) => el.variable),
                        sources: variableDisplayData[0].sources,
                        types: variableDisplayData[0].types,
                        variableExtended:
                          variableDisplayData[0].variable.variableExtended,
                        varTypes: varTypes,
                        variablesExtend: variablesExtend,
                      },
                    }}
                  >
                    <CustomButton 
                      color="primary" 
                      tooltip={tooltips?.details || "Explore selected variables"}
                      helpModeActive={helpModeActive}
                    >
                      Go to details
                    </CustomButton>
                  </Link>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} md={6}>
                <Demo>
                  <List>
                    {variableDisplayData.map((variable, index) => (
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              const newVariables = JSON.parse(
                                JSON.stringify(variables)
                              );
                              newVariables[variable.ind].booleanValue = false;
                              setVariables(newVariables);
                              setVariableDisplayData(
                                variableDisplayData.filter(
                                  (el, i) => i !== index
                                )
                              );
                            }}
                          >
                            <CustomTooltip 
                              title={tooltips?.delete_icon || 'Remove from the list'}
                              disableFocusListener={!helpModeActive}
                              disableHoverListener={!helpModeActive}
                              disableTouchListener={!helpModeActive}
                            >
                              <DeleteIcon />
                            </CustomTooltip>
                          </IconButton>
                        }
                        key={index}
                      >
                        <ListItemText primary={variable.variable} />
                      </ListItem>
                    ))}
                  </List>
                </Demo>
              </Grid>
              {variables && (
                <Grid item xs={12}>
                  <MaterialTable
                    title="Variables"
                    columns={columns}
                    data={variables}
                    options={{
                      tableLayout: 'fixed',
                      paging: false,
                      maxBodyHeight: window.innerHeight - 330,
                      emptyRowsWhenPaging: false,
                      pageSize: 6,
                      draggable: false,
                    }}
                    components={{
                      Row: ({ data }) => renderData(data),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

const ConnectedDataAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataAnalysis);
export default withStyles(dashboardStyle)(ConnectedDataAnalysis);
