export const GET_PLREPORT_DATA = "GET_PLREPORT_DATA";

export const RECEIVED_REPORT = 'RECEIVED_REPORT';
export const RECEIVED_PNL_PERIOD_PARKS_DATA = 'RECEIVED_PNL_PERIOD_PARKS_DATA';
export const UPDATE_PNL_INTERVAL_CURRENCY_TYPE = 'UPDATE_PNL_INTERVAL_CURRENCY_TYPE';

export const LOADING_REPORT = 'LOADING_REPORT';

export const LOGGED_IN = 'LOGGED_IN';
export const UINFO = 'UINFO';
export const TARGETS = 'TARGETS';

export const CHANGE_CONNECTION = 'CHANGE_CONNECTION';
export const CHANGE_VISIBLE_ROUTES = 'CHANGE_VISIBLE_ROUTES';


export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DATADASHBOARD_DATA = 'GET_DATADASHBOARD_DATA';
export const GET_TRADESDASHBOARD_DATA = 'GET_TRADESDASHBOARD_DATA';
export const GET_MARKETSDATA_DATA = 'GET_MARKETSDATA_DATA';
export const GET_OPTIMISATION_DATA = 'GET_OPTIMISATION_DATA';
export const GET_RTRADER_DATA = 'GET_RTRADER_DATA';
export const GET_RTRADER_MARKETDEPTH = 'GET_RTRADER_MARKETDEPTH';
export const GET_RTRADER_MARKETTRADES = 'GET_RTRADER_MARKETTRADES';
export const GET_FUNDAMENTALS_AUDIT = 'GET_FUNDAMENTALS_AUDIT';
export const GET_FUNDAMENTALS_CURVE = 'GET_FUNDAMENTALS_CURVE';
export const GET_MARKET_SCORES = 'GET_MARKET_SCORES';

export const RECEIVED_DASHBOARD_DATA = 'RECEIVED_DASHBOARD_DATA';
export const RECEIVED_DATADASHBOARD_DATA = 'RECEIVED_DATADASHBOARD_DATA';
export const RECEIVED_TRADESDASHBOARD_DATA = 'RECEIVED_TRADESDASHBOARD_DATA';
export const RECEIVED_MARKETSDATA_DATA = 'RECEIVED_MARKETSDATA_DATA';
export const RECEIVED_MARKET_SCORE = 'RECEIVED_MARKET_SCORE'
export const RECEIVED_OPTIMISATION_DATA = 'RECEIVED_OPTIMISATION_DATA';
export const RECEIVED_RTRADER_DATA = 'RECEIVED_RTRADER_DATA';
export const RECEIVED_RTRADER_MARKETDEPTH = 'RECEIVED_RTRADER_MARKETDEPTH';
export const RECEIVED_RTRADER_MARKETTRADES = 'RECEIVED_RTRADER_MARKETTRADES';
export const RECEIVED_FUNDAMENTALS_AUDIT = 'RECEIVED_FUNDAMENTALS_AUDIT';
export const RECEIVED_FUNDAMENTALS_CURVE = 'RECEIVED_FUNDAMENTALS_CURVE';
export const RECEIVED_STRUCTURED_ALERTS = 'RECEIVED_STRUCTURED_ALERTS';
export const RECEIVED_COMPL_EXCS = 'RECEIVED_COMPL_EXCS';
export const LOADING_DASHBOARD_DATA = 'LOADING_DASHBOARD_DATA';
export const LOADING_DATADASHBOARD_DATA = 'LOADING_DATADASHBOARD_DATA';
export const LOADING_TRADESDASHBOARD_DATA = 'LOADING_TRADESDASHBOARD_DATA';
export const LOADING_MARKETSDATA_DATA = 'LOADING_MARKETSDATA_DATA';
export const LOADING_MARKET_SCORE = 'LOADING_MARKET_SCORE';
export const LOADING_OPTIMISATION_DATA = 'LOADING_OPTIMISATION_DATA';
export const LOADING_RTRADER_DATA = 'LOADING_RTRADER_DATA';
export const LOADING_RTRADER_MARKETDEPTH = 'LOADING_RTRADER_MARKETDEPTH';
export const LOADING_RTRADER_MARKETTRADES = 'LOADING_RTRADER_MARKETTRADES';
export const LOADING_FUNDAMENTALS_AUDIT = 'LOADING_FUNDAMENTALS_AUDIT';
export const LOADING_FUNDAMENTALS_CURVE = 'LOADING_FUNDAMENTALS_CURVE';
export const LOADING_STRUCTURED_ALERTS = 'LOADING_STRUCTURED_ALERTS';
export const LOADING_COMPL_EXCS = 'LOADING_COMPL_EXCS';
export const RECEIVED_MODELS_DATA = 'RECEIVED_MODELS_DATA';
export const LOADING_MODEL_DATA = 'LOADING_MODEL_DATA';
export const RECEIVED_CATEGORIES = 'RECEIVED_CATEGORIES';
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES';
export const GET_NOTIFICATIONS_DATA = 'GET_NOTIFICATIONS_DATA';

export const GET_JOBS_DATA = 'GET_JOBS_DATA';

export const RECEIVED_JOBS_DATA = 'RECEIVED_JOBS_DATA';

export const LOADING_JOBS_DATA = 'LOADING_JOBS_DATA';

export const RECEIVED_NOTIFICATIONS_DATA = 'RECEIVED_NOTIFICATIONS_DATA';

export const LOADING_NOTIFICATIONS_DATA = 'LOADING_NOTIFICATIONS_DATA';

export const GET_GENERATION_DATA = 'GET_GENERATION_DATA';

export const RECEIVED_GENERATION_DATA = 'RECEIVED_GENERATION_DATA';
export const RECEIVED_GENERATION_DATA_CHUNK = 'RECEIVED_GENERATION_DATA_CHUNK';
export const RECEIVED_GENERATION_DATA_ALL = 'RECEIVED_GENERATION_DATA_ALL';
export const RESET_GENERATION_DATA_ALL_LOADED = 'RESET_GENERATION_DATA_ALL_LOADED';

export const LOADING_GENERATION_DATA = 'LOADING_GENERATION_DATA';
export const LOADING_CHUNKS_GENERATION_DATA = 'LOADING_CHUNKS_GENERATION_DATA';


export const GET_MARKETS_DATA = 'GET_MARKETS_DATA';

export const RECEIVED_MARKETS_DATA = 'RECEIVED_MARKETS_DATA';

export const LOADING_MARKETS_DATA = 'LOADING_MARKETS_DATA';

export const GET_METRICS_DATA = 'GET_METRICS_DATA';

export const RECEIVED_METRICS_DATA = 'RECEIVED_METRICS_DATA';
export const RECEIVED_METRICS_DATA_CHUNK = 'RECEIVED_METRICS_DATA_CHUNK';
export const RECEIVED_METRICS_DATA_ALL = 'RECEIVED_METRICS_DATA_ALL';
export const RECEIVED_METRICS_DATA_NOT_ALL = 'RECEIVED_METRICS_DATA_NOT_ALL';
export const RECEIVED_SUB_DA_METRICS_DATA = 'RECEIVED_SUB_DA_METRICS_DATA';
export const RECEIVED_SUB_DA_METRICS_DATA_CHUNK = 'RECEIVED_SUB_DA_METRICS_DATA_CHUNK';
export const RECEIVED_SUB_DA_METRICS_DATA_ALL = 'RECEIVED_SUB_DA_METRICS_DATA_ALL';
export const RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL = 'RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL';
export const RESET_METRICS_DATA_ALL_LOADED = 'RESET_METRICS_DATA_ALL_LOADED';
export const STOP_METRICS_DATA = 'STOP_METRICS_DATA';

export const LOADING_METRICS_DATA = 'LOADING_METRICS_DATA';
export const LOADING_CHUNKS_METRICS_DATA = 'LOADING_CHUNKS_METRICS_DATA';

export const LOADING_PNL_ANALYSIS_DATA = 'LOADING_PNL_ANALYSIS_DATA';
export const LOADING_PARKS_DATA = 'LOADING_PARKS_DATA';
export const PRELOADING_PNL_ANALYSIS_DATA = 'PRELOADING_PNL_ANALYSIS_DATA';
export const PRELOADED_PNL_ANALYSIS_DATA = 'PRELOADED_PNL_ANALYSIS_DATA';
export const ERROR_PRELOADING_PNL_ANALYSIS_DATA = 'ERROR_PRELOADING_PNL_ANALYSIS_DATA';
export const RECEIVED_PNL_ANALYSIS_DATA = 'RECEIVED_PNL_ANALYSIS_DATA';
export const RECEIVED_TOP_STRATEGIES_DATA = 'RECEIVED_TOP_STRATEGIES_DATA';
export const RECEIVED_PNL_ANALYS_PARKS_DATA = 'RECEIVED_PNL_ANALYS_PARKS_DATA';

export const UPDATE_PARKS_INFO = 'UPDATE_PARKS_INFO';

export const UPDATE_ACTIVE_PARK = 'UPDATE_ACTIVE_PARK';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_OPTIMISATION_TYPE = 'UPDATE_OPTIMISATION_TYPE';
export const SHOW_OPTIMISATION_NON_ACTIVE = 'SHOW_OPTIMISATION_NON_ACTIVE';
export const UPDATE_CURRENT_REGION = 'UPDATE_CURRENT_REGION';
export const UPDATE_CURRENT_ANALYS_MARKET = 'UPDATE_CURRENT_ANALYS_MARKET';

export const UPDATE_PNL_CURRENT_SETTINGS = 'UPDATE_PNL_CURRENT_SETTINGS';
export const UPDATE_BATTERY_TRADING_SETTINGS = 'UPDATE_BATTERY_TRADING_SETTINGS';
export const UPDATE_LOGIN_INSTANCES = 'UPDATE_LOGIN_INSTANCES';

export const LOADING_AUDIT_SUMMARY = 'LOADING_AUDIT_SUMMARY';
export const UPDATE_MARKET_FILTER = 'UPDATE_MARKET_FILTER';
export const UPDATE_VARIABLE_FILTER = 'UPDATE_VARIABLE_FILTER';
export const UPDATE_SOURCE_FILTER = 'UPDATE_SOURCE_FILTER';
export const UPDATE_AUDITOR_FILTER = 'UPDATE_AUDITOR_FILTER';
export const UPDATE_IMPORTANCE_FILTER = 'UPDATE_IMPORTANCE_FILTER';
export const UPDATE_DATE_FROM = 'UPDATE_DATE_FROM';
export const UPDATE_DATE_TO = 'UPDATE_DATE_TO';
export const UPDATE_AUDIT_SEARCH = 'UPDATE_AUDIT_SEARCH';
export const UPDATE_AUDIT_ALERTS_TYPE = 'UPDATE_AUDIT_ALERTS_TYPE';
export const UPDATE_AUDIT_ALERTS_STATUS = 'UPDATE_AUDIT_ALERTS_STATUS';

export const SHOW_NON_ACTIVE_PARKS = 'SHOW_NON_ACTIVE_PARKS';