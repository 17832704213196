import React, { useEffect } from "react";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// core components
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import Jobs from 'components/Jobs/Jobs';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoginPage from "views/Login/Oops.jsx";
import { get_jobs_data } from "actions/index";
import moment from "moment";

const headerStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 2,
  color: primaryColor,
  fontWeight: 500,
  fontSize: '1em',
  padding: '12px 0',
  textAlign: 'center',
};

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs,
    schedule: state.jobs.schedule,
    tableHead: state.jobs.tableHead,
    tableData: state.jobs.tableData,
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    loading: state.jobs.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_jobs_data: data => dispatch(get_jobs_data(data))
});

const JobsReport = ({ 
    isLoggedIn, 
    loading, 
    classes, 
    get_jobs_data, 
    jobs, 
    tableHead, 
    tableData, 
    schedule,
  }) => {
  const scheduleHeads = [{title: 'Name', field: 'name'}, {title: 'Run Time', field: 'next_run_time'}];
  
  useEffect(() => {
    get_jobs_data();
  }, [])
  
  const renderRows = (data) => {
    return (
      <TableRow>
        <TableCell>
          {data.name}
        </TableCell>
        <TableCell align="center">
          {moment(data.next_run_time).utc().format('MMM DD HH:mm')}
        </TableCell>
      </TableRow>
    )
  }

  if (isLoggedIn) {
    if (loading) {
      return (
        <div className="loader" alt="Loading report..."/>
      );
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader color="primary">
               <h4 className={classes.cardTitleWhite}>Jobs list</h4>
            </CardHeader>
            <CardBody >
              <Jobs
                tableHead={tableHead}
                tableData={tableData}
                jobs={jobs}
                winHeight={window.innerHeight - 230}
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Jobs Schedule</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                columns={scheduleHeads}
                data={schedule}
                options={{
                  toolbar: true,
                  showTitle: false,
                  tableLayout: 'fixed',
                  paging: false,
                  maxBodyHeight: window.innerHeight - 230,
                  headerStyle: headerStyle,
                  emptyRowsWhenPaging: false,
                  pageSize: 6,
                  draggable: false,
                  search: true,
                }}
                components={{
                  Row: ({ data }) => renderRows(data),
                }}
              />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (<LoginPage />);
  }
}

const ConnectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsReport);
export default withStyles(tableStyle)(ConnectedJobs);
