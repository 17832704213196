import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const CustomMap = ({markers}) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);

  const capitals = {
    'Albania': { lat: 41.3275, lng: 19.8189 },
    'Andorra': { lat: 42.5077, lng: 1.5211 },
    'Austria': { lat: 48.2082, lng: 16.3738 },
    'Belarus': { lat: 53.9006, lng: 27.5590 },
    'Belgium': { lat: 50.8503, lng: 4.3517 },
    'Bosnia and Herzegovina': { lat: 43.8563, lng: 18.4131 },
    'Bulgaria': { lat: 42.6977, lng: 23.3219 },
    'Croatia': { lat: 45.8150, lng: 15.9819 },
    'Czechia': { lat: 50.0755, lng: 14.4378 },
    'Denmark': { lat: 55.6761, lng: 12.5683 },
    'Estonia': { lat: 59.4370, lng: 24.7536 },
    'Finland': { lat: 60.1695, lng: 24.9354 },
    'France': { lat: 48.8566, lng: 2.3522 },
    'Germany': { lat: 52.5200, lng: 13.4050 },
    'Greece': { lat: 37.9838, lng: 23.7275 },
    'Hungary': { lat: 47.4979, lng: 19.0402 },
    'Iceland': { lat: 64.1355, lng: -21.8954 },
    'Ireland': { lat: 53.3498, lng: -6.2603 },
    'Italy': { lat: 41.9028, lng: 12.4964 },
    'IT-NORD': { lat: 45.4642, lng: 9.1900 },
    'IT-CNOR': { lat: 43.7696, lng: 11.2558 },
    'IT-CSUD': { lat: 41.9028, lng: 12.4964 },
    'IT-SUD': { lat: 40.8518, lng: 14.2681 },
    'IT-CALA': { lat: 38.9092, lng: 16.5876 },
    'IT-SICI': { lat: 38.1157, lng: 13.3615 },
    'IT-SARD': { lat: 39.2238, lng: 9.1217 },
    'Hokkaido': { lat: 43.0642, lng: 141.3469 },
    'Tohoku': { lat: 38.2682, lng: 140.8694 },
    'Kanto': { lat: 35.6895, lng: 139.6917 },
    'Tokyo': { lat: 35.6895, lng: 139.6917 },
    'Chubu': { lat: 35.1802, lng: 136.9066 },
    'Kansai': { lat: 34.6937, lng: 135.5022 },
    'Chugoku': { lat: 34.3853, lng: 132.4553 },
    'Shikoku': { lat: 33.8417, lng: 132.7657 },
    'Kyushu': { lat: 33.5904, lng: 130.4017 },
    'Okinawa': { lat: 26.2124, lng: 127.6809 },
    'Hokuriku': { lat: 36.5613, lng: 136.6562 },
    'Tokai': { lat: 35.1802, lng: 136.9066 },
    'Koshinetsu': { lat: 37.9022, lng: 138.2539 },
    'Sanin': { lat: 35.5039, lng: 134.2351 },
    'Sanyo': { lat: 34.6913, lng: 133.9189 },
    'Northern Kyushu': { lat: 33.6064, lng: 130.4181 },
    'Southern Kyushu': { lat: 31.5966, lng: 130.5571 },
    'Latvia': { lat: 56.9496, lng: 24.1052 },
    'Lithuania': { lat: 54.6872, lng: 25.2797 },
    'Luxembourg': { lat: 49.6117, lng: 6.1319 },
    'Malta': { lat: 35.8997, lng: 14.5146 },
    'Moldova': { lat: 47.0105, lng: 28.8638 },
    'Monaco': { lat: 43.7384, lng: 7.4246 },
    'Montenegro': { lat: 42.4410, lng: 19.2627 },
    'Netherlands': { lat: 52.3676, lng: 4.9041 },
    'North Macedonia': { lat: 41.9981, lng: 21.4254 },
    'Norway': { lat: 59.9139, lng: 10.7522 },
    'Poland': { lat: 52.2297, lng: 21.0122 },
    'Portugal': { lat: 38.7223, lng: -9.1393 },
    'Romania': { lat: 44.4268, lng: 26.1025 },
    'Russia': { lat: 55.7558, lng: 37.6173 },
    'San Marino': { lat: 43.9336, lng: 12.4508 },
    'Serbia': { lat: 44.8176, lng: 20.4569 },
    'Slovakia': { lat: 48.1486, lng: 17.1077 },
    'Slovenia': { lat: 46.0569, lng: 14.5058 },
    'Spain': { lat: 40.4168, lng: -3.7038 },
    'Sweden': { lat: 59.3293, lng: 18.0686 },
    'Switzerland': { lat: 46.9481, lng: 7.4474 },
    'Ukraine': { lat: 50.4501, lng: 30.5234 },
    'United Kingdom': { lat: 51.5074, lng: -0.1278 },
    'United States': { lat: 38.9072, lng: -77.0369 },
    'Canada': { lat: 45.4215, lng: -75.6972 },
    'Mexico': { lat: 19.4326, lng: -99.1332 },
    'Japan': { lat: 35.6895, lng: 139.6917 },
  };
  

  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.setCenter({ lat: 49, lng: 71 });
    const mapContainerElement = map.getDiv();

    const handleWheel = (e) => {
      e.stopPropagation();
    };

    if (mapContainerElement) {
      mapContainerElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (mapContainerElement) {
        mapContainerElement.removeEventListener('wheel', handleWheel);
      }
    };
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={{ width: "100%", height: "500px" }}
        zoom={4}
      >
        {markers && Object.keys(markers).map(type => {
          if (type === 'Flat Volume') {
            return Object.keys(markers[type]).map(country => {
              return (
                <Marker
                  key={country}
                  position={capitals[country]}
                  icon={{
                     url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24" fill="%23FF0000"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><circle cx="12" cy="9" r="2.5" fill="%23B20000"/></svg>`
                  }}
                  onMouseOver={() => setSelectedMarker({...markers[type][country], name: country})}
                  onMouseOut={() => setSelectedMarker(null)}
                >
                  {selectedMarker?.name === country && (
                    <InfoWindow 
                      position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                      options={{
                        disableAutoPan: true
                      }}
                    >
                      <div style={{position: 'relative', fontSize: '16px', padding: '0 10px'}}>
                        <style>
                          {
                            // disabling close icon 
                            `.gm-ui-hover-effect {
                                display: none !important;
                              }
                            `
                          }
                        </style>
                        {`${selectedMarker.name}: ${selectedMarker.totalVolume} MW`}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
            })
          } else {
            return Object.entries(markers[type]).map(([country, countryData]) => {
              return Object.entries(countryData).map(([city, data]) => {
                const generators = Object.keys(data).filter(key => key === 'solar' || key === 'wind');
                if (generators.length) {
                  return generators.map(generator => {
                    const colors = generator === 'solar' ? ['%23FFFF00', '%23FFA500'] : ['%23008000', '%23004d00'];
                    return (
                      <Marker
                        key={country + city + generator}
                        icon={{
                          url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24" fill="${colors[0]}"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><circle cx="12" cy="9" r="2.5" fill="${colors[1]}"/></svg>`
                        }}
                        position={{
                          lat: data.lat,
                          lng: data.lng
                        }}
                        onMouseOver={() => setSelectedMarker({...markers[type][country][city], name: `${country}, ${city}`})}
                        onMouseOut={() => setSelectedMarker(null)}
                      >
                        {selectedMarker?.name === `${country}, ${city}` && (
                          <InfoWindow 
                            position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                            options={{
                              disableAutoPan: true
                            }}
                          >
                            <div style={{position: 'relative', fontSize: '16px', padding: '0 10px'}}>
                              <style>
                                {
                                  // disabling close icon 
                                  `.gm-ui-hover-effect {
                                      display: none !important;
                                    }
                                  `
                                }
                              </style>
                              {selectedMarker[generator].parks.map((park) => (
                                <div key={park.id} style={{textAlign: 'left'}}>{`${park.name}: ${park.tradedVolume} MW`}</div>
                              ))}
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    )
                  })
                }

                return (
                  <Marker
                    key={country + city}
                    icon={{
                      url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24" fill="%23008000"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><circle cx="12" cy="9" r="2.5" fill="%23004d00"/></svg>`
                    }}
                    position={{
                      lat: data.lat,
                      lng: data.lng
                    }}
                    onMouseOver={() => setSelectedMarker({...markers[type][country][city], name: `${country}, ${city}`})}
                    onMouseOut={() => setSelectedMarker(null)}
                  >
                    {selectedMarker?.name === `${country}, ${city}` && (
                      <InfoWindow 
                        position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                        options={{
                          disableAutoPan: true
                        }}
                      >
                        <div style={{position: 'relative', fontSize: '16px', padding: '0 10px'}}>
                          <style>
                            {
                              // disabling close icon 
                              `.gm-ui-hover-effect {
                                  display: none !important;
                                }
                              `
                            }
                          </style>
                          {selectedMarker.parks.map((park) => (
                            <div key={park.id} style={{textAlign: 'left'}}>{`${park.name}: ${park.tradedVolume} MW`}</div>
                          ))}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )
              })
            })
          }
        })}
      </GoogleMap>
    </LoadScript>
  )
}

export default CustomMap;